const DEBUG = false;

const toCamel = (s: string) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const styleToObj = (style: string) => {
  const ret: any = {};

  if (!style) return ret;
  if (typeof style !== 'string') return ret;

  const parts = style.split(';');

  parts.forEach((p) => {
    const line = p.trim();

    if (line && line.indexOf(':') > -1) {
      let [key, value] = line.split(':', 2);
      key = toCamel(key.trim());
      value = value.trim();
      ret[key] = value;
    }
  });

  return ret;
};

export const getData = (data: any, path: string, def: any) => {
  const elements = path.split('.');
  let tmp = data;
  if (data) {
    for (let i = 0; i < elements.length; i++) {
      let element: any = elements[i];

      if (element.indexOf('[') > -1) {
        // array
        const parts = element.split(/\[|\]/);
        if (parts.length === 3) {
          if (typeof tmp[parts[0]] !== 'undefined') {
            tmp = tmp[parts[0]];
            element = parseInt(parts[1]);
          } else {
            tmp = null;
            break;
          }
        }
      }

      if (typeof tmp[element] !== 'undefined') {
        tmp = tmp[element];
      } else {
        tmp = null;
        break;
      }
    }
  }

  if (tmp) return tmp;
  return def;
};

const languagesByCode = window.config.languages.reduce(function (map: any, obj) {
  map[obj] = true;
  return map;
}, {});

export const getText = (data: any, path: string, def: any) => {
  const elements: string[] = path.split('.');
  let tmp = data;
  if (data) {
    for (let i = 0; i < elements.length; i++) {
      let element: string | number = elements[i];

      if (element.indexOf('[') > -1) {
        // array
        const parts = element.split(/\[|\]/);
        if (parts.length === 3) {
          if (typeof tmp[parts[0]] !== 'undefined') {
            tmp = tmp[parts[0]];
            element = parseInt(parts[1]);
          } else {
            tmp = null;
            break;
          }
        }
      }

      if (tmp[element] != null) {
        if (languagesByCode[element]) {
          let txtLang = tmp[element];
          if (!txtLang) {
            // there is no translation for that languagel fallback in english
            if (tmp['en']) {
              txtLang = tmp['en'];
            }
          }
          tmp = txtLang;
        } else {
          tmp = tmp[element];
        }
      } else {
        tmp = null;
        break;
      }
    }
  }

  if (tmp) return tmp;
  return def;
};

export const convertToElementTemplateFormat = (object: any) => {
  if (!object) return {};

  const result: { [id: string]: any } = {};

  Object.keys(object).forEach((k: any) => {
    const o: any = object[k];
    if (typeof o === 'object') {
      result[k] = {
        element_type: 'internal',
        data: convertToElementTemplateFormat(o),
      };
    } else {
      result[k] = o;
    }
  });
  return result;
};
