import store from '@/store';
import { FeatureCategory, FeatureLocation, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

// Helper function for logging the event
export const logBetEvent = (event: any, betData: any, betsState: any) => {
  try {
    // Bundle all analytics-specific work into the logEvent call
    // so it only runs if analytics are enabled
    logEvent(
      event,
      {
        feature_used: identiftFeatureUsed(betData, betsState),
        feature_category: identifyFeatureCategory(betData),
        feature_subcategory: identifyFeatureSubcategory(betData),
        feature_location: identifyFeatureLocation(),
      },
      { match_details: extractBetDetails(betData, betsState) },
    );
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

/**
 * Identify the feature location based on the current page.
 *
 * @returns A string representing the feature location.
 */
const identifyFeatureLocation = (): FeatureLocation => {
  const chatOpen = store.getState().social.socialHub.isOpen;

  return chatOpen ? FeatureLocation.Chat : FeatureLocation.MainContent;
};

/**
 * Identify the feature used based on the bet details.
 *
 * @returns A string representing the feature used.
 */
const identiftFeatureUsed = (betData: any, betsState: any): string => {
  const matchType = betData.mtype;
  const selectedMatches = betsState.betsSlip.tickets[betsState.betsSlip.currentTicket][matchType].selected;

  // if any of the selected matches is the same as the current match, then the feature used is 'Bet removed from betslip'
  if (selectedMatches.some((match: any) => match.idMbo === betData.idmbo)) {
    return 'Bet removed from betslip';
  }

  // if bet is from the same match and different market, then the feature used is 'Bet replaced in betslip'
  // if (selectedMatches.some((match: any) => match.idMb === betData.idmb)) {
  if (selectedMatches.some((match: any) => match.idMatch === betData.idmatch)) {
    return 'Bet replaced in betslip';
  }

  return 'Bet added to betslip';
};

/**
 * Identify the feature category based on the bet details.
 *
 * @returns A string representing the feature category.
 */
const identifyFeatureCategory = (betData: any): FeatureCategory => {
  if (betData.brmid) {
    return FeatureCategory.BetsRecommendation;
  }

  return FeatureCategory.Bets;
};

/**
 * Identify the feature subcategory based on the bet details.
 *
 * @returns A string representing the feature subcategory.
 */
const identifyFeatureSubcategory = (betData: any): FeatureSubcategory => {
  // what would a feature subcategory be?

  return FeatureSubcategory.NotApplicable;
};

/**
 * Extract the bet details from the bet data.
 *
 * @returns An object containing the bet details.
 */
const extractBetDetails = (betData: any, betsState: any) => {
  const matchType = betData.mtype;
  const selectedMatches = betsState.betsSlip.tickets[betsState.betsSlip.currentTicket][matchType].selected;

  // if bet is from the same match and different market, also add the old market to the payload
  // if (selectedMatches.some((match: any) => match.idMb === betData.idmb)) {
  // const oldMatch = selectedMatches.find((match: any) => match.idMb === betData.idmb);
  if (selectedMatches.some((match: any) => match.idMatch === betData.idmatch)) {
    const oldMatch = selectedMatches.find((match: any) => match.idMatch === betData.idmatch);

    return {
      brmId: betData?.brmid,
      mType: betData?.mtype,
      idSport: betData?.idsport,
      idMatch: betData?.idmatch,
      idBet: betData?.idbet,
      idMb: betData?.idmb,
      idBo: betData?.idbo,
      idMbo: betData?.idmbo,
      replacedBet: {
        brmId: oldMatch?.brmId,
        mType: oldMatch?.match?.mtype,
        idSport: oldMatch?.idSport,
        idMatch: oldMatch?.idMatch,
        idBet: oldMatch?.idBet,
        idMb: oldMatch?.idMb,
        idBo: oldMatch?.idBo,
        idMbo: oldMatch?.idMbo,
      },
    };
  }

  return {
    brmId: betData?.brmid,
    mType: betData?.mtype,
    idSport: betData?.idsport,
    idMatch: betData?.idmatch,
    idBet: betData?.idbet,
    idMb: betData?.idmb,
    idBo: betData?.idbo,
    idMbo: betData?.idmbo,
  };
};
