import store from '@/store';
import { FeatureCategory, FeatureLocation, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

// Types and Interfaces
interface NavigationTypeConfig {
  feature_used: (properties?: any, enrichedContext?: any) => string;
  category: (properties?: any, enrichedContext?: any) => FeatureCategory;
  subcategory: (properties?: any, enrichedContext?: any) => FeatureSubcategory;
  location: (properties?: any, enrichedContext?: any) => FeatureLocation;
  getPayload: (properties: any, enrichedContext: any) => any;
}

interface SportMatchPath {
  sport: { id: string };
  type: 'live' | 'pre-match';
  tournamentId?: string;
  competitionId?: string;
  matchId?: string;
}

interface SlotGamePath {
  gameId: string;
  gameName: string;
}

interface ParsedUrl {
  basePath: string;
  queryParams: Record<string, string>;
  fullPath: string;
  pathData: SportMatchPath | SlotGamePath | null;
}

interface WalletBonus {
  id: string;
  name: string;
  type: string;
  status: string;
  amount: number;
  amountGranted: number;
  wager: number;
  wagerTarget: number;
  startDate: string;
  endDate: string;
  bonusId: string;
}

interface KeywordMapping<T> {
  value: T;
  keywords: string[];
}

// URL Parsing
class UrlParser {
  static parse(href: string): ParsedUrl {
    const [path, queryString] = href.split('?');
    const pathParts = path.split('/').filter(Boolean);

    const parsedUrl: ParsedUrl = {
      basePath: '/' + pathParts[0],
      pathData: null,
      queryParams: this.parseQueryParams(queryString),
      fullPath: path,
    };

    if (pathParts[0] === 'slot-game') {
      parsedUrl.pathData = {
        gameId: pathParts[1],
        gameName: pathParts[2],
      };
    } else if (pathParts[0] === 'bets' && pathParts[1] === 'match') {
      parsedUrl.basePath = '/bets/match';
      parsedUrl.pathData = {
        type: pathParts[2] as 'live' | 'pre-match',
        sport: { id: pathParts[3] },
        tournamentId: pathParts[4],
        competitionId: pathParts[5],
        matchId: pathParts[6],
      };
    }

    return parsedUrl;
  }

  private static parseQueryParams(queryString?: string): Record<string, string> {
    if (!queryString) return {};

    return Object.fromEntries(
      queryString.split('&').map((param) => {
        const [key, value] = param.split('=');
        return [decodeURIComponent(key), decodeURIComponent(value || '')];
      }),
    );
  }
}

// Feature Identification Utilities
const createKeywordMatcher =
  <T>(mappings: KeywordMapping<T>[], defaultValue: T) =>
  (input: string): T => {
    if (!input) return defaultValue;

    const normalizedInput = input.toLowerCase();
    const match = mappings.find(({ keywords }) => keywords.some((keyword) => normalizedInput.includes(keyword)));

    return match ? match.value : defaultValue;
  };

const CATEGORY_MAPPINGS: KeywordMapping<FeatureCategory>[] = [
  { value: FeatureCategory.Promotions, keywords: ['promo', 'promotion'] },
  { value: FeatureCategory.Navigation, keywords: ['navigation', 'nav'] },
];

const LOCATION_MAPPINGS: KeywordMapping<FeatureLocation>[] = [
  { value: FeatureLocation.MainContent, keywords: ['main', 'content', 'main page'] },
  { value: FeatureLocation.Header, keywords: ['header'] },
  { value: FeatureLocation.Footer, keywords: ['footer'] },
  { value: FeatureLocation.Sidebar, keywords: ['sidebar'] },
  { value: FeatureLocation.Modal, keywords: ['modal'] },
  { value: FeatureLocation.GameInterface, keywords: ['game'] },
  { value: FeatureLocation.InGameNotification, keywords: ['inGameNotification'] },
  { value: FeatureLocation.Betslip, keywords: ['betslip'] },
];

const SUBCATEGORY_MAPPINGS: KeywordMapping<FeatureSubcategory>[] = [
  { value: FeatureSubcategory.Carousel, keywords: ['carousel', 'slider', 'slides'] },
  { value: FeatureSubcategory.Stories, keywords: ['stories', 'story'] },
  { value: FeatureSubcategory.Banner, keywords: ['banner'] },
  { value: FeatureSubcategory.GameSearch, keywords: ['game search'] },
  { value: FeatureSubcategory.WalletControls, keywords: ['wallet', 'wallet controls'] },
  { value: FeatureSubcategory.NotApplicable, keywords: ['n/a', 'not applicable'] },
];

const identifyFeatureUsed = (feature_used: string): string => feature_used;
const identifyFeatureCategory = createKeywordMatcher(CATEGORY_MAPPINGS, FeatureCategory.Unspecified);
const identifyFeatureLocation = createKeywordMatcher(LOCATION_MAPPINGS, FeatureLocation.Unspecified);
const identifyFeatureSubcategory = createKeywordMatcher(SUBCATEGORY_MAPPINGS, FeatureSubcategory.Unspecified);

// Default handler utilities
const getDefaultFeature = (prefix: string) => (properties: Record<string, string>) =>
  identifyFeatureUsed(`${prefix}_navigation_${properties['data-feature-used'] || 'unspecified'}`);

const getDefaultCategory = (properties: Record<string, string>) =>
  identifyFeatureCategory(properties['data-feature-category'] || 'navigation');

const getDefaultLocation = (properties: Record<string, string>) =>
  identifyFeatureLocation(properties['data-feature-location'] || 'main');

// Handler implementations
const depositHandler: NavigationTypeConfig = {
  feature_used: getDefaultFeature('deposit'),
  category: getDefaultCategory,
  subcategory: (properties) => identifyFeatureSubcategory(properties['data-feature-sub-category'] || 'unspecified'),
  location: getDefaultLocation,
  getPayload: (properties, enrichedContext) => {
    const walletState = store.getState().wallet;

    const bonuses_data = walletState?.bonuses.map((bonus: WalletBonus) => ({
      id: bonus.id,
      name: bonus.name,
      type: bonus.type,
      status: bonus.status,
      amount: bonus.amount,
      amountGranted: bonus.amountGranted,
      wager: bonus.wager,
      wagerTarget: bonus.wagerTarget,
      startDate: bonus.startDate,
      endDate: bonus.endDate,
      bonusId: bonus.bonusId,
    }));

    return {
      navigate_to: properties.href,
      wallet_info: {
        main: walletState?.main,
        bonus: walletState?.bonus,
        total: walletState?.total,
        currency: walletState?.currency,
        actve_bonuses: bonuses_data,
      },
      queryParams: enrichedContext.queryParams,
      'data-evt-id': properties['data-evt-id'],
      'data-evt-source': properties['data-evt-source'],
    };
  },
};

const matchBetsHandler: NavigationTypeConfig = {
  feature_used: (properties) => getDefaultFeature(properties['data-mtype'] || 'match')(properties),
  category: (properties, enrichedContext) =>
    enrichedContext?.brmId ? FeatureCategory.BetsRecommendation : getDefaultCategory(properties),
  subcategory: (properties) => identifyFeatureSubcategory(properties['data-feature-sub-category'] || 'not applicable'),
  location: getDefaultLocation,
  getPayload: (properties, enrichedContext) => ({
    navigate_to: properties.href,
    queryParams: enrichedContext.queryParams,
    recommended_bet_id: enrichedContext?.brmId,
  }),
};

const createSlotGamePayload = (properties: Record<string, string>, enrichedContext: any): any => {
  const baseSlotData = {
    id: enrichedContext.id,
    name: enrichedContext.name,
    url: enrichedContext.url,
    provider_id: enrichedContext.providerId,
    players: enrichedContext.players,
    index: enrichedContext._index,
  };

  if (enrichedContext?.dsId) {
    return {
      ...baseSlotData,
      unique_gameplay_attributes: enrichedContext.metadata_game_attribute_unique_gameplay_attributes,
      dsId: enrichedContext.dsId,
      index: enrichedContext.slideNo,
    };
  }

  return baseSlotData;
};

const slotGameHandler: NavigationTypeConfig = {
  feature_used: (properties, enrichedContext) =>
    enrichedContext?.dsId
      ? identifyFeatureUsed(`slot_game_navigation_${enrichedContext.dsId}_${properties['data-feature-used']}`)
      : getDefaultFeature('slot_game')(properties),
  category: getDefaultCategory,
  subcategory: (properties, enrichedContext) => {
    if (properties['data-feature-sub-category']) {
      return identifyFeatureSubcategory(properties['data-feature-sub-category']);
    }
    return enrichedContext?.dsId ? FeatureSubcategory.Carousel : FeatureSubcategory.Unspecified;
  },
  location: getDefaultLocation,
  getPayload: (properties, enrichedContext) => ({
    navigate_to: properties.href,
    queryParams: enrichedContext.queryParams,
    slot_game_data: createSlotGamePayload(properties, enrichedContext),
  }),
};

// Main handler mapping
const HREF_TYPE_HANDLERS: Record<string, NavigationTypeConfig> = {
  '/deposit': depositHandler,
  '/bets/match': matchBetsHandler,
  '/slot-game': slotGameHandler,
};

// Main logging function
export const logNavigationEvent = (event: any, properties: Record<string, any>, dataElementContext: any): void => {
  try {
    const parsedUrl = UrlParser.parse(properties.href);
    const handler = HREF_TYPE_HANDLERS[parsedUrl.basePath];

    if (!handler) {
      console.debug(`Skipping logging for href type: ${parsedUrl.basePath}`);
      return;
    }

    const enrichedContext = {
      ...dataElementContext,
      ...parsedUrl,
    };

    logEvent(
      event,
      {
        feature_used: handler.feature_used(properties, enrichedContext),
        feature_category: handler.category(properties, enrichedContext),
        feature_subcategory: handler.subcategory(properties, enrichedContext),
        feature_location: handler.location(properties, enrichedContext),
      },
      handler.getPayload(properties, enrichedContext),
    );
  } catch (error) {
    console.error('Error logging navigation event:', error);
  }
};