import React from 'react';
import { delay } from 'redux-saga/effects';

import ClientAPI from '../ClientAPI/ClientAPI';
import Translate from './Translate';

const WAIT_TIME = 3000;

class Utils {
  /**
   * @param id
   * @param funct function
   * @param time integer
   * @returns {*}
   */
  static delayedFunction(id, funct, time = WAIT_TIME) {
    let hash = this.hash(id);
    let i = 0;
    if (typeof this.timers !== 'undefined') {
      for (; i <= this.timers.length; i++) {
        if (typeof this.timers[i] !== 'undefined' && this.timers[i].hash === hash) {
          clearTimeout(this.timers[i].id);
          this.timers[i] = null;
          break;
        }
      }
    } else {
      this.timers = [];
    }

    let timer = setTimeout(funct, time);
    this.timers[i] = {
      hash: hash,
      id: timer,
    };
    return timer;
  }

  /**
   * Calculate a 32 bit FNV-1a hash
   * Found here: https://gist.github.com/vaiorabbit/5657561
   * Ref.: http://isthe.com/chongo/tech/comp/fnv/
   *
   * @param {string} str the input value
   * @param {integer} [seed] optionally pass the hash of the previous chunk
   * @returns {integer | string}
   */
  static hash(str, seed) {
    let i,
      l,
      hval = seed === undefined ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i++) {
      hval ^= str.charCodeAt(i);
      hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    // Convert to 8 digit hex string
    return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
  }

  /**
   * [WEB-323] Currency should be Lei (not LEI or RON)
   * @param currency
   * @returns string
   */
  static formatCurrency(currency) {
    switch (currency.toLowerCase()) {
      case 'ron':
      case 'lei':
        return 'Lei';
      case 'wincoin':
        return 'W';
      case 'free spins':
        return 'Free Spins';
      case 'free bet':
        return 'Free Bet';
      default:
        return currency;
    }
  }

  static generateNumericRandomId() {
    return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
  }

  /**
   * check if it's integer
   * @param n
   * @returns {boolean}
   */
  static isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  /**
   * check if it's float
   * @param n
   * @returns {boolean}
   */
  static isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  /**
   * this function will play the sound, compatible with new versions of Chrome and the new versions of Safari (macOS High Sierra)
   * if the promise was rejected, it will continue without throwing an error. An info message will be visible in development mode.
   * @param sound
   */
  static playSound(sound) {
    let promise = sound.play();
    if (promise !== undefined) {
      promise.catch((error) => {
        console.log('[Play sound error] Error message:', error);
      });
    }
  }

  /**
   * this function will pause the sound, compatible with new versions of Chrome and the new versions of Safari (macOS High Sierra)
   * if the promise was rejected, it will continue without throwing an error. An info message will be visible in development mode.
   * @param sound
   */
  static pauseSound(sound) {
    let promise = sound.pause();
    if (promise !== undefined) {
      promise.catch((error) => {
        console.log('[Pause sound error] Error message:', error);
      });
    }
  }

  /**
   * Amount format with no fancy style
   *
   * @param amount String/Number
   * @param decimals Boolean -- show decimals if decimals found
   * @param currency String/NULL
   * @param forceDecimals Boolean -- force decimals to appear if amount is smaller than 100
   * @returns String -- formatted amount as a string
   */
  static formatAmount(amount, decimals = false, currency = null, forceDecimals = false) {
    let value = Number(amount).toFixed(2);
    let locales = 'ro-RO';
    let type = {};
    let minimumFractionDigits = 0;
    let maximumFractionDigits = 2;
    let formattedCurrency = null;

    if (!decimals) {
      value = Math.floor(value);
      maximumFractionDigits = 0;
    }

    if (forceDecimals && value.length < 6) {
      minimumFractionDigits = 2;
    }

    if (currency) {
      formattedCurrency = Utils.formatCurrency(currency);
      type.style = 'currency';
      type.currency = formattedCurrency;
    }

    type.minimumFractionDigits = minimumFractionDigits;
    type.maximumFractionDigits = maximumFractionDigits;

    let formattedAmount = new Intl.NumberFormat(locales, type).format(value);
    if (formattedCurrency) {
      formattedAmount = formattedAmount.replace(formattedCurrency.toUpperCase(), formattedCurrency);
    }

    return formattedAmount;
  }

  static formatSmallDecimals = (value, currency = null) => {
    let formattedValue = value
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    if (currency !== null) {
      currency = Utils.formatCurrency(currency);
      currency = currency.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
    }
    if (formattedValue.includes(',')) {
      let valueArray = formattedValue.split(',');
      formattedValue = (
        <span>
          {valueArray[0]}
          <small className="SmallDecimal">,{valueArray[1]}</small>
          {currency !== null ? ' ' + currency : ''}
        </span>
      );
    }
    return formattedValue;
  };

  static compareEtas(a, b) {
    let timeA = 0;
    let timeB = 0;

    if (typeof a.etaTime !== 'undefined') {
      timeA = a.etaTime;
    }
    if (typeof b.etaTime !== 'undefined') {
      timeB = b.etaTime;
    }

    if (timeA > timeB) {
      return 1;
    }

    if (timeA < timeB) {
      return -1;
    }

    return 0;
  }

  // this functions wait for the axios plugin to receive the Authorization token, and returns it.
  static *getAxiosWithToken() {
    let axios = ClientAPI.getInstance();
    //console.log('try to get axios with token');
    if (typeof axios.defaults.headers.common.Authorization === 'undefined') {
      yield delay(100);
      return yield this.getAxiosWithToken();
    }
    return axios;
  }

  static getImageUrl(img) {
    if (import.meta.env.MODE === 'development') {
      img = img.replace('/..', window.config.front_url);
    }

    return img;
  }

  static fixRegisterInputs(id, time) {
    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      if (typeof time === 'undefined') {
        time = 1;
      }
      time += time;
      let el = document.getElementById(id);
      if (el && time < 5000) {
        el.click();
        Utils.delayedFunction('fixIOS' + id, this.fixRegisterInputs.bind(this, id, time), time);
      }
    }
  }

  static t(text, placeholder) {
    return <Translate text={text} placeholder={placeholder} />;
  }

  static isActiveThisRequest(id, min = 1000) {
    return true;
  }

  static setCookie(name, value, days, path = '/', secure = null, sameSite = false) {
    let d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    let cookie = `${name}=${value};${expires};path=${path}`;
    if (secure) {
      cookie += ';Secure';
    }
    if (sameSite) {
      cookie += `;SameSite=${sameSite}`;
    }

    document.cookie = cookie;
  }

  static getCookie(name) {
    name = name + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  static injectScript(src, id, callback, options) {
    let lastScript = document.scripts[document.scripts.length - 1];
    let script = document.createElement('script');
    script.id = id;
    if (options) {
      if (typeof options.async !== 'undefined') {
        script.async = options.async;
      }
      if (typeof options.data !== 'undefined') {
        // loop through data and set them as attributes
        for (let key in options.data) {
          script.setAttribute(`data-${key}`, options.data[key]);
        }
      }
    }
    script.type = 'text/javascript';
    script.src = src;
    script.onload = script.onreadystatechange = function () {
      if (this.readyState && this.readyState !== 'complete' && this.readyState !== 'loaded') {
        return;
      }
      if (callback) {
        callback();
      }
    };
    let found = document.getElementById(id);
    if (!found) {
      if (options.container) {
        const container = document.getElementById(options.container);
        if (container) {
          container.appendChild(script);
        } else if (options.documentContainerFallback) {
          lastScript.parentElement.insertBefore(script, lastScript.nextSibling);
        }
      } else {
        lastScript.parentElement.insertBefore(script, lastScript.nextSibling);
      }
    }
  }

  static getQueryParam(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  }

  static encodeString(string) {
    string = btoa(string);
    if (string.slice('-2') === '==') {
      string.slice(0, -2);
    }
    string += btoa(Math.random()).slice(0, 7);
    return btoa(string);
  }

  static decodeString(hash) {
    console.log(hash);
    hash = hash.splice(0, -7);
    try {
      return btoa(hash + '==');
    } catch (e) {
      return btoa(hash);
    }
  }

  static formatCardNumber(cardNumber) {
    return cardNumber.substring(0, 4) + ' **** **** ' + cardNumber.substring(cardNumber.length - 4);
  }

  static removeScript(id) {
    let script = document.getElementById(id);
    if (script) {
      script.remove();
    }
  }

  stopDelayedFunction(id) {
    let hash = this.hash(id);
    let i = 0;
    if (typeof this.timers !== 'undefined') {
      for (; i <= this.timers.length; i++) {
        if (typeof this.timers[i] !== 'undefined' && this.timers[i].hash === hash) {
          clearTimeout(this.timers[i].id);
          this.timers[i] = null;
          break;
        }
      }
    }
  }
}

export default Utils;
