import * as actionTypes from './../actions/actionTypes';
import ResponseErrorMessages from '@/modules/casino/store/errors/ResponseErrorMessages.js';

const realInitialState = {
  errors: {
    phone: undefined,
    email: undefined,
    password: undefined,
    otp: undefined,
    global: undefined,
  },

  signedUp: undefined,
  signedUpErrors: undefined,
  signUpLoading: false,

  partialAccount: {
    loading: false,
    error: false,
    errorCode: undefined,
    errorMessage: undefined,
    existingAccount: false,
    existingAccountData: {
      // email: undefined,
      phone: undefined,
      phone_country_code: undefined,
    },
  },
};

const initialState = realInitialState;

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.register.SET_REGISTER_SIGNUP_LOADING:
      return {
        ...state,
        signUpLoading: action.data ?? false,
      };

    case actionTypes.register.VALIDATE_FIELD_RESPONSE:
      const errors: any = { ...state.errors };
      errors[action.field] = action.message;
      return {
        ...state,
        errors: errors,
      };
    case actionTypes.register.SET_PARTIAL_ACCOUNT:
      return {
        ...state,
        partialAccount: {
          ...state.partialAccount,
          loading: true,
        },
      };

    case actionTypes.register.PARTIAL_ACCOUNT_RESPONSE:
      // console.log('DEBUG PARTIAL_ACCOUNT_RESPONSE REDUCER: ', action);

      return {
        ...state,
        partialAccount: {
          ...state.partialAccount,
          loading: false,
          error: !action.data.success,
          errorCode: action.data.errorCode ?? undefined,
          errorMessage: action.data.errorMessage
            ? action.data.errorMessage
            : action.data.errorCode
              ? ResponseErrorMessages.get(action.data.errorCode)
              : undefined,
          existingAccount: action.data.existingAccount ?? false,
          existingAccountData: {
            phone: action.data.existingAccountData?.phone ?? undefined,
            phone_country_code: action.data.existingAccountData?.phone_country_code ?? undefined,
          },
        },
      };
    case actionTypes.register.RESET_REGISTER:
      return {
        ...realInitialState,
      };

    case actionTypes.register.SEND_REGISTER_SMS_RESPONSE:
      // console.log('DEBUG SEND_REGISTER_SMS_RESPONSE REDUCER: ', action.data);

      let verifyPhoneError: any = undefined;
      if (typeof action.data.ResponseCode !== 'undefined') {
        if (typeof action.data.ResponseMessage === 'string') {
          verifyPhoneError = action.data.ResponseMessage;
        } else {
          verifyPhoneError = ResponseErrorMessages.get(action.data.ResponseCode);
        }
      }

      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          otp: verifyPhoneError,
          // global: verifyPhoneError,
          phone: verifyPhoneError,
        },
      };

    case actionTypes.register.REGISTER_RESPONSE:
      let signedUp = true;
      let signedUpErrors: any = undefined;

      // console.log('DEBUG [REGISTER_RESPONSE] REDUCER:', action);
      // action.error = array of errors

      if (typeof action.errors !== 'undefined') {
        // case when we have a response code
        if (typeof action.errors.ResponseCode !== 'undefined') {
          // if is string
          signedUp = false;
          signedUpErrors = ResponseErrorMessages.get(action.errors.ResponseCode);
        } else {
          // case when we have a validation message
          if (typeof action.errors.validation_messages !== 'undefined') {
            signedUp = false;
            signedUpErrors = action.errors.validation_messages;
          } else {
            if (typeof action.errors.errors !== 'undefined') {
              signedUp = false;
              signedUpErrors = action.errors.errors[0];
              if (typeof signedUpErrors[1] !== 'undefined') {
                signedUpErrors = signedUpErrors[1];
              } else {
                // check if obj
                if (typeof signedUpErrors === 'object') {
                  signedUpErrors = Object.values(signedUpErrors).join(' , '); // TODO: check if we display only 1 error
                }
              }
            }

            if (action.errors && Array.isArray(action.errors) && action.errors.length > 0) {
              signedUp = false;
              signedUpErrors = action.errors[0];
              // TODO: Error handling
            }
          }
        }
      } else {
        signedUp = action.value;
        if (!signedUp) {
          signedUpErrors = 'unknown sign up error';
        }
      }
      return {
        ...state,
        signUpLoading: false,
        signedUp: signedUp,
        errors: {
          ...state.errors,
          global: signedUpErrors,
        },
      };

    default:
      return state;
  }
};

export default reducer;
