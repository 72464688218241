import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSources } from './dataSources';

type SlotGamesOrderMap = Record<string | 'length', number>;

interface SlotGame {
  id: number | string;
  name: string;
  provider_id: string;
  _pinned?: boolean;
}

interface ReelGame {
  gameId: number | string;
  name: string;
  category: number | string;
  backgroundImagePath?: string;
  headerLogoPath?: string;
  headerLogoTextPath?: string;
  hlsPath?: string;
}

type Game = SlotGame | ReelGame;

function isSlotGame(game: Game): game is SlotGame {
  return game && typeof game === 'object' && 'id' in game;
}

const dataSourceId = window.config.dataSourceAllPlayerGames;

interface PlayerGamesOrder {
  items: SlotGamesOrderMap;
  loading: boolean;
  loaded: boolean;
  error?: string;
}

export const playerGamesOrderSlice = createSlice({
  name: 'playerGamesOrder',
  initialState: <PlayerGamesOrder>{
    items: {
      length: 0,
    },
    loading: false,
    loaded: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSources.fulfilled, (state, action) => {
        if (action.payload.success && action.payload.data && action.payload.data[dataSourceId]) {
          state.loading = false;
          state.loaded = true;
          state.items = mapGamesToIdsMap(action.payload.data[dataSourceId].data);
        }
      });
  },
});

function isGameDataSource(dataSource: any): boolean {
  return dataSource && dataSource?.element_type?.type_id === 'slot_game';
}

function isReelDataSource(dataSource: any): boolean {
  return dataSource && dataSource?.element_type?.type_id === 'video-reel';
}

function mapGamesToIdsMap(data: Game[]): SlotGamesOrderMap {
  const map: SlotGamesOrderMap = {};

  data.forEach((game, index) => {
    if (isSlotGame(game)) {
      map[game.id] = index;
    } else {
      map[game.gameId] = index;
    }
  });

  map.length = data.length;

  return map;
}

const BLACKLIST: string[] = ['slot-games-cold', 'slot-games-hot', 'slot-games-popular-now'];

export function sortGamesDataSources(
  dataSources: Record<string, any>,
  orderMap: SlotGamesOrderMap,
): Record<string, any> {
  const gamesDataSources: Record<string, any> = {};

  if (!orderMap.length && dataSources[dataSourceId]) {
    orderMap = mapGamesToIdsMap(dataSources[dataSourceId].data);
  }

  if (!orderMap.length) {
    return gamesDataSources;
  }

  Object.keys(dataSources).forEach((sourceId) => {
    if (sourceId === dataSourceId || BLACKLIST.includes(sourceId)) {
      return;
    }

    const dataSource = dataSources[sourceId];

    if (isGameDataSource(dataSource) || isReelDataSource(dataSource)) {
      // First, sort only unpinned games
      const unpinnedGames = dataSource.data
        .filter((game: Game) => isSlotGame(game) ? !game._pinned : true)
        .sort((a: Game, b: Game) => {
          // If not found in order map, put them at the end of the list
          const firstItem = orderMap[isSlotGame(a) ? a.id : a.gameId] ?? orderMap.length;
          const secondItem = orderMap[isSlotGame(b) ? b.id : b.gameId] ?? orderMap.length + 1;

          return firstItem - secondItem;
        });

      const sorted: SlotGame[] = [];
      let sortedIndex = 0;

      // Second, push pinned games in same position and unpinned games in between
      for (let i = 0; i < dataSource.data.length; i++) {
        if (dataSource.data[i]._pinned) {
          sorted.push(dataSource.data[i]);
        } else {
          sorted.push(unpinnedGames[sortedIndex]);
          sortedIndex++;
        }
      }

      gamesDataSources[sourceId] = {
        ...dataSource,
        data: sorted,
      };
    }
  });

  return gamesDataSources;
}

export function listHasGamesDataSources(ids: string[]): boolean {
  return ids.some((id) => id.toLowerCase().includes('games') || id.toLocaleLowerCase().includes('reels'));
}

export function responseHasGamesDataSources(dataSources: Record<string, any>): boolean {
  let hasGameDataSources = false;

  Object.keys(dataSources).every((sourceId) => {
    const dataSource = dataSources[sourceId];

    if (isGameDataSource(dataSource)) {
      hasGameDataSources = true;

      return false;
    }

    return true;
  });

  return hasGameDataSources;
}

export default playerGamesOrderSlice.reducer;
