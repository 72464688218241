import moment from 'moment';
import 'moment/dist/locale/ro';

import { stdLiveScore } from '@/modules/bets/utils/matchUtils';
import { MatchType, StateBetType, OutcomeType } from './types';
import store from '@/store';
import { getTranslatedText } from '@/page-components/utils/functions';

export const liveScore = stdLiveScore;

export const floorFigure = (figure: number, decimals: number = 2) => {
  if (!decimals) decimals = 2;

  if (typeof figure === 'string') {
    figure = parseFloat(figure);
    if (isNaN(figure)) {
      return null;
    }
  } else if (figure == null) {
    return null;
  }

  const d = Math.pow(10, decimals);
  return ((figure * d) / d).toFixed(decimals);
};

export const testValues = (v1: any, v2: any, zeroSpecial: any = false) => {
  if ((typeof v1 === 'undefined' || v1 === null) && (typeof v2 === 'undefined' || v2 === null)) {
    return 0;
  }

  if (typeof v1 === 'undefined' || v1 === null) {
    return 1;
  }

  if (typeof v2 === 'undefined' || v2 === null) {
    return -1;
  }

  if (Array.isArray(v1) && v1.length > 0) {
    v1 = v1[0];
  }

  if (Array.isArray(v2) && v2.length > 0) {
    v2 = v2[0];
  }

  let iv1 = parseFloat(v1 + '');
  let iv2 = parseFloat(v2 + '');

  if (isNaN(iv1) && isNaN(iv2)) {
    const sv1 = v1 + '';
    const sv2 = v2 + '';
    return sv1.toLowerCase().localeCompare(sv2.toLowerCase());
  }

  if (isNaN(iv1)) {
    return 1;
  }

  if (isNaN(iv2)) {
    return -1;
  }

  if (zeroSpecial) {
    if (iv1 === 0) iv1 = 9999;
    if (iv2 === 0) iv2 = 9999;
  }

  return iv1 - iv2;
};

const months: { [id: string]: string } = {
  '1': 'Ian',
  '2': 'Feb',
  '3': 'Mart',
  '4': 'Apr',
  '5': 'Mai',
  '6': 'Iun',
  '7': 'Iul',
  '8': 'Aug',
  '9': 'Sept',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

export const formatDate = (date: number, lang: string, noHour = false, divs = false) => {
  const refDate = moment();
  const tomorrow = moment().add(1, 'days');
  let dt = moment(date);
  if (lang === 'ro') {
    dt = moment(date).locale('ro');
  } else {
    dt = moment(date).locale('en');
  }

  if (refDate.isSame(dt, 'day')) {
    if (noHour) {
      return `${lang === 'ro' ? 'Azi - ' : 'Today - '}`;
    }
    if (divs) {
      return `<div>${lang === 'ro' ? 'Azi - ' : 'Today - '}</div><div>${dt.format('HH:mm')}</div>`;
    }
    return `${lang === 'ro' ? 'Azi - ' : 'Today - '} ${dt.format('HH:mm')}`;
  }
  if (tomorrow.isSame(dt, 'day')) {
    if (noHour) {
      return `${lang === 'ro' ? 'Maine - ' : 'Tomorrow - '}`;
    }
    if (divs) {
      return `<div>${lang === 'ro' ? 'Maine - ' : 'Tomorrow - '}</div><div>${dt.format('HH:mm')}</div>`;
    }
    return `${lang === 'ro' ? 'Maine - ' : 'Tomorrow - '} ${dt.format('HH:mm')}`;
  }

  if (lang === 'ro') {
    if (divs) {
      if (noHour) {
        return `<div>${dt.format('ddd')}</div><div>${
          dt.format('D ') + months[dt.format('M')].toUpperCase().replace('.', '')
        }</div>`;
      }
      return `<div>${dt.format('ddd')}</div><div>${
        dt.format('D ') + months[dt.format('M')].toUpperCase().replace('.', '') + '</div><div>' + dt.format('HH:mm')
      }</div>`;
    }
    if (noHour) {
      return `${dt.format('ddd, D ')}${months[dt.format('M')].toUpperCase().replace('.', '')}`;
    }
    return `${dt.format('ddd, D ')}${months[dt.format('M')].toUpperCase().replace('.', '')} - ${dt.format('HH:mm')}`;
  }
  if (divs) {
    if (noHour) {
      return `<div>${dt.format('ddd')}</div><div>${dt.format('MMM').toUpperCase().replace('.', '')}${dt.format(
        ' D',
      )}</div>`;
    }
    return `<div>${dt.format('ddd')}</div><div>${dt.format('MMM').toUpperCase().replace('.', '')}${
      dt.format(' D') + '</div><div>' + dt.format('HH:mm')
    }</div>`;
  }
  if (noHour) {
    return `${dt.format('ddd, ')}${dt.format('MMM').toUpperCase().replace('.', '')}${dt.format(' D')}`;
  }
  return `${dt.format('ddd, ')}${dt.format('MMM').toUpperCase().replace('.', '')}${dt.format(' D')} - ${dt.format(
    'HH:mm',
  )}`;
};

type BetsType = {
  [isSport: string]: {
    [idBet: string]: StateBetType;
  };
};

export const formatBetTitle = (mb: any, match: MatchType | null, bets: BetsType | null, language: string) => {
  if (mb == null || match == null) {
    return '';
  }

  if (mb.mbDisplayName) {
    if (match.periodShortName) {
      const mbDisplayName: any = {};
      Object.keys(mb.mbDisplayName).forEach((key: any) => {
        if (match.periodShortName[key]) {
          mbDisplayName[key] = match.periodShortName[key] + ' - ' + mb.mbDisplayName[key];
        } else {
          mbDisplayName[key] = mb.mbDisplayName[key];
        }
      });
      return mbDisplayName;
    }

    return mb.mbDisplayName;
  }

  let t = '';

  if (!bets) {
    const state = store.getState();
    let bs: any = null;
    if (match.mType === 'live') {
      bs = state.bets.live.bets;
    } else {
      bs = state.bets.prematch.bets;
    }
    if (bs && typeof bs[match.idSport] !== 'undefined' && mb.idBet in bs[match.idSport]) {
      t = bs[match.idSport][mb.idBet].betName;
    } else {
      return '';
    }
  } else if (bets && typeof bets[match.idSport] !== 'undefined' && mb.idBet in bets[match.idSport]) {
    t = bets[match.idSport][mb.idBet].betName;
  } else {
    return '';
  }

  if (typeof t === 'object') {
    // if (language === 'ro') {
    //   t = t['42'] ?? t['2'] ?? t['0'] ?? '';
    // } else {
    //   t = t['2'] ?? t['0'] ?? '';
    // }
    t = getTranslatedText(t);
  }

  let specialValue = '';

  if (Array.isArray(mb.mbSpecialValue)) {
    if (mb.mbSpecialValue.length > 0) {
      specialValue = mb.mbSpecialValue[0];
    }
  } else if (mb.mbSpecialValue) {
    specialValue = mb.mbSpecialValue;
  }

  if (specialValue !== '' && specialValue !== '*') {
    const mbsv = specialValue.split('|');
    const phs = 'XYZ';

    let changed = false;

    mbsv.forEach((v, i) => {
      const ph = phs.charAt(i);

      if (t.indexOf(`[${ph}]`) > -1) {
        t = t.split(`[${ph}]`).join(v);
        changed = true;
      }
    });

    if (!changed) {
      t += ' (' + specialValue + ')';
    }
  }

  if (mb.competitors && mb.competitors.length > 0) {
    t +=
      ' - ' +
      mb.competitors
        .map((c: any) => {
          let player = c.name;

          if (c.id && match.idTeam1 && match.idTeam1 === c.id.toString()) {
            player += ` (${match.team1Name})`;
          } else if (c.id && match.idTeam2 && match.idTeam2 === c.id.toString()) {
            player += ` (${match.team2Name})`;
          }

          return player;
        })
        .join(', ');
  }

  return t;
};

export const formatOddName = (
  idBet: string,
  odd: OutcomeType,
  match: MatchType,
  bets: BetsType | null,
  language: string,
) => {
  let outcome = odd.mboDisplayName;

  if (!outcome) {
    if (!bets) {
      const state = store.getState();
      let bs: any = null;
      if (match.mType === 'live') {
        bs = state.bets.live.bets;
      } else {
        bs = state.bets.prematch.bets;
      }
      if (odd.idBo in bs[match.idSport][idBet]['betOutcomes']) {
        outcome = bs[match.idSport][idBet]['betOutcomes'][odd.idBo]['betOutcomeName'];
      } else {
        outcome = 'n/a';
      }
    } else if (bets && bets[match.idSport] && idBet in bets[match.idSport]) {
      if (odd.idBo in bets[match.idSport][idBet]['betOutcomes']) {
        outcome = bets[match.idSport][idBet]['betOutcomes'][odd.idBo]['betOutcomeName'];
      } else {
        outcome = 'n/a';
      }
    }
  }

  if (typeof outcome === 'object') {
    // if (language === 'ro') {
    //   outcome = outcome['42'] ?? outcome['2'] ?? outcome['0'];
    // } else {
    //   outcome = outcome['2'] ?? outcome['0'];
    // }

    outcome = getTranslatedText(outcome);
  }

  if (odd.argument) {
    outcome += ' ' + odd.argument;
  }

  return outcome;
};

export const canCashout = (match: MatchType) => {
  let canCashout = false;
  if (match && match.matchBets) {
    for (const mb of match.matchBets) {
      for (const mbo of mb.mbOutcomes) {
        if (mbo.isCashoutEnabled) {
          canCashout = true;
          break;
        }
      }

      if (canCashout) {
        break;
      }
    }
  }

  return canCashout;
};

export const formatTotalOddsValue = (value: any, noZeros: boolean) => {
  let v: any = parseFloat(value);
  let suffix = '';

  if (value < 1000) {
    v = v.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else if (value < 10000) {
    v = v.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else if (value < 100000) {
    v = v.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else if (value < 1000000) {
    v = (v / 1000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    suffix = 'k';
  } else if (value < 1000000000) {
    v = (v / 1000000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    suffix = 'm';
  } else if (value < 1000000000000) {
    v = (v / 1000000000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    suffix = 'g';
  } else {
    v = (v / 1000000000000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    suffix = 't';
  }

  v = v.toString();

  if (noZeros) {
    v = v.replace(/(\.0+)$/, '');
  }

  return v + suffix;
};

const fact = (n: number) => {
  if (n === 0 || n === 1) {
    return 1;
  }

  for (let i = n - 1; i >= 1; i--) {
    n *= i;
  }

  return n;
};

export const comb = (n: number, r: number) => {
  return Math.round(fact(n) / (fact(r) * fact(n - r))).toFixed(0);
};

export const getTr = (textAny: any, language: string) => {
  let text: any = textAny;

  if (typeof text === 'object') {
    // if (text['0'] || text['2'] || text['42']) {
    //   const lang: any = {
    //     en: '2',
    //     ro: '42',
    //   };

    //   if (text[lang[language]]) {
    //     text = text[lang[language]];
    //   } else {
    //     text = text['2'] ?? text['0'] ?? '';
    //   }
    // }
    text = getTranslatedText(text);
  }

  if (typeof text !== 'string') {
    return '';
  }

  return text ?? '';
};

export const cloneOmit = (obj: any, keys: any): any => {
  const newObject = { ...obj };
  keys.forEach((key: any) => {
    if (Object.prototype.hasOwnProperty.call(newObject, key)) {
      delete newObject[key];
    }
  });

  return newObject;
};
