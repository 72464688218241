// src/analytics/models/eventDataModel.ts

export interface EventData {
  session_id: string; // Unique ID for the visit/session TODO: SESSION
  timestamp: string; // Timestamp of the event
  user: UserData; // User-related information
  page: PageData; // Page-related information
  interaction: InteractionData; // Interaction-related information
  product: ProductData; // Product or feature-related information
  // marketing: MarketingData;        // Marketing-related information
  event_payload: EventPayload; // Additional custom data specific to the event
  // errors: Record<string, any>;     // Any errors associated with the event (if applicable)
  // performance: PerceivedPerformance; // Perceived performance data

  page_visit_id: string; // Unique ID for each page visit (all events triggered on this page will have the same visit ID)
}

export interface UserData {
  user_id: string | null; // User ID from the _store.getState()
  user_auth: true | false; // User authentication status
  user_global_id: string | null; // Global user ID
  device: any; // Any because it uses ClientDetection.getDeviceData()
  location: LocationData; // Location-related information
}

export interface LocationData {
  ip_address: string; // User's IP address
  city: string; // City name
  country: string; // Country name
  region: string; // Region or state name
}

export interface PageData {
  url: string; // URL of the page where the event occurred
  title: string; // Title of the page
  referrer: string; // Referrer URL (previous page)
  language: string; // Language of the page (or user's browser locale)
  viewport: { width: number; height: number }; // Viewport dimensions
  scroll_depth: string; // Scroll depth (e.g., '50%')
  // visit_history: string[];         // History of visited pages in the session
}

export interface InteractionData {
  interaction_type: string; // Type of interaction (e.g., 'click', 'hover', 'scroll')
  element_id: string; // ID of the interacted element (if available)
  element_type: string; // Type of the interacted element (e.g., 'button', 'link', 'image')
  element_data_source: string; // Data source of the element (if available, e.g., 'data-tracking' attribute)
  element_text: string; // Text content of the interacted element (if applicable)
  element_position: string; // Position of the element in the DOM (if relevant)
  element_state: string; // State of the element (e.g., 'enabled', 'disabled')

  // percentage x and y of the click event relative to the whole page
  click_position: { x: number; y: number } | null;

  // touch coordinates
  touch_coordinates?: { from: { x: number; y: number }; to: { x: number; y: number } };

  // containing category of the element (e.g., 'hot games', 'new games', 'popular games')
  // can be null if no category is found in the element or parent elements
  element_category?: string | null;

  // element label
  element_label: string;
}

export interface ProductData {
  feature_used: string;
  feature_category: FeatureCategory;
  feature_subcategory: FeatureSubcategory;
  feature_location: FeatureLocation; // Location of the feature on the page
}

export interface PerceivedPerformance {
  time_taken: string; // Time a task or interaction (e.g., '120ms')
  render_time: string; // Time for rendering (e.g., '80ms')
}

export interface MarketingData {
  source: string; // Source of the traffic (e.g., 'google', 'facebook')
  channel: string; // Marketing channel (e.g., 'organic', 'paid')
}

export interface EventPayload {
  [key: string]: any; // Custom event-specific data; anything. maybe all _store.getState()
}
// Location where features can appear in the UI
export enum FeatureLocation {
  // Content Areas
  MainContent = 'Main Content',
  Header = 'Header',
  Footer = 'Footer',
  Sidebar = 'Sidebar',
  Chat = 'Chat',
  Feed = 'Feed',

  // Overlays
  Modal = 'Modal',
  InGameNotification = 'InGameNotification',

  // Game-specific
  GameInterface = 'Game Interface',
  Betslip = 'Betslip',

  // Defaults
  NotApplicable = 'N/A',
  Unspecified = 'Unspecified',
}

// Main categorization of features
export enum FeatureCategory {
  // Gaming Features
  Casino = 'Casino',
  Bets = 'Bets',
  LiveGame = 'Live Game',
  SlotGame = 'Slot Game',
  Tournaments = 'Tournaments',

  // Monetization Features
  Wallet = 'Wallet',
  Promotions = 'Promotions',
  Rewards = 'Rewards',

  // Social Features
  SocialHub = 'Social Hub',

  // Core Features
  Authentication = 'Authentication',
  Navigation = 'Navigation',
  Search = 'Search',
  UserPreferences = 'User Preferences',

  // Game-Specific Features
  MomentumTurboCash = 'Momentum Turbo Cash',
  MomentumTurboWins = 'Momentum Turbo Wins',
  BetsRecommendation = 'Bets Recommendation',
  Achievements = 'Achievements',

  // System Features
  System = 'System',
  Error = 'Error',

  // Defaults
  NotApplicable = 'N/A',
  Unspecified = 'Unspecified',
}

// Specific subcategories for features
export enum FeatureSubcategory {
  // Betting Components
  PlaceTicket = 'Place Ticket',

  // UI Components
  Carousel = 'Carousel',
  Stories = 'Stories',
  Banner = 'Banner',

  // Search Types
  SmartSearch = 'Smart Search',
  GameSearch = 'Game Search',
  BetsSearch = 'Bets Search',

  // Wallet Features
  PaymentForm = 'Payment Form',
  TransactionHistory = 'Transaction History',
  WalletControls = 'Wallet Controls',
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal',

  // System Features
  Notification = 'Notification',
  SystemEvent = 'System Event',
  SystemError = 'System Error',

  // Defaults
  NotApplicable = 'N/A',
  Unspecified = 'Unspecified',
}