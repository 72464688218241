import store from '@/store';
import { FeatureCategory, FeatureLocation, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

interface CarouselTypeConfig {
  featureUsed: (carouselType: string, data_source_id: string, event_type: string, properties?: any) => string;
  category: (properties?: string) => FeatureCategory;
  subcategory: (properties?: any) => FeatureSubcategory;
  location: (properties?: any) => FeatureLocation;
  getPayload: (data: any, index?: number) => any;
}

const CAROUSEL_TYPE_HANDLERS: Record<string, CarouselTypeConfig> = {
  // currently story comes from the same carouselType as banner
  // we do the check in the subcategory function
  banner: {
    featureUsed: (carouselType, data_source_id, event_type, properties) => {
      if (properties && properties.id && properties.id.toLowerCase().includes('stor')) {
        return `story_${data_source_id}_${event_type}`;
      }
      return `${carouselType}_${data_source_id}_${event_type}`;
    },
    category: () => FeatureCategory.Promotions,
    subcategory: (properties) => {
      if (!properties) {
        return FeatureSubcategory.Carousel;
      } else {
        if (properties.id && properties.id.toLowerCase().includes('stor')) {
          return FeatureSubcategory.Stories;
        }
        return FeatureSubcategory.Carousel;
      }
    },
    location: () => FeatureLocation.MainContent,
    getPayload: (data, index) => ({
      BannerContent: {
        id: data.id,
        main_link: data.cta_href_primary,
        secondary_link: data.cta_href_secondary,
        BannerIndex: index,
      },
    }),
  },
  'bets.data.tournament': {
    featureUsed: (carouselType, data_source_id, event_type) => `${carouselType}_${data_source_id}_${event_type}`,
    category: () => FeatureCategory.Tournaments,
    subcategory: () => FeatureSubcategory.Carousel,
    location: () => FeatureLocation.MainContent,
    getPayload: (data, index) => ({
      TournamentContent: {
        TournamentId: data.idTournament,
        TournamentSportName: data.sportName,
        TournamentShortName: data.tournamentShortName,
        TournamentIndex: index,
      },
    }),
  },

  slot_game: {
    featureUsed: (carouselType, data_source_id, event_type) => `${carouselType}_${data_source_id}_${event_type}`,
    category: () => FeatureCategory.SlotGame,
    subcategory: () => FeatureSubcategory.Carousel,
    location: () => FeatureLocation.MainContent,
    getPayload: (data, index) => ({
      SlotGameContent: {
        // WholeData without art_bundle
        WholeData: data ? (({ art_bundle = {}, ...rest }) => rest)(data) : {},
        GameIndex: index,
      },
    }),
  },

  'recommended-bets-carousel': {
    featureUsed: (carouselType, data_source_id, event_type) => `${carouselType}_${data_source_id}_${event_type}`,
    category: () => FeatureCategory.BetsRecommendation,
    subcategory: () => FeatureSubcategory.Carousel,
    location: () => FeatureLocation.MainContent,
    getPayload: (data, index) => ({
      RecommendedBetsContent: {
        brmId: data.brmId,
        brmIdMb: data.brmIdMb,
        brmIdMbo: data.brmIdMbo,
        idCategory: data.idCategory,
        idMatch: data.idMatch,
        idSport: data.idSport,
        idTeam1: data.idTeam1,
        idTeam2: data.idTeam2,
        idTournament: data.idTournament,
        mType: data.mType,
        recommendedBetIndex: index,
      },
    }),
  },
  'recently-played-carousel': {
    // treat is like a normal slot game
    featureUsed: (carouselType, data_source_id, event_type) => `slot_game_${carouselType}_${event_type}`,
    category: () => FeatureCategory.SlotGame,
    subcategory: () => FeatureSubcategory.Carousel,
    location: () => FeatureLocation.MainContent,

    getPayload: (data, index) => ({
      SlotGameContent: {
        // WholeData without art_bundle
        WholeData: data ? (({ art_bundle = {}, ...rest }) => rest)(data) : {},
        GameIndex: index,
      },
    }),
  },

  'tournaments-slots-carousel': {
    // treat is like a normal slot game
    featureUsed: (carouselType, data_source_id, event_type) => `slot_game_${carouselType}_${event_type}`,
    category: () => FeatureCategory.Tournaments,
    subcategory: () => FeatureSubcategory.Carousel,
    location: () => FeatureLocation.MainContent,
    getPayload: (data, index) => ({
      SlotGameContent: {
        // WholeData without art_bundle
        WholeData: data ? (({ art_bundle = {}, ...rest }) => rest)(data) : {},
        GameIndex: index,
      },
    }),
  },
};



export const logCarouselEvent = (
  event: Event | MouseEvent | TouchEvent | KeyboardEvent | null,
  event_type: string,
  data_source: any[],
  data_source_id: string,
  dataElementTypeId: string,
  previousIndex: number,
  properties?: any,
): void => {
  try {
    const carouselType = dataElementTypeId || properties?.dsInnerType;

    const handler = CAROUSEL_TYPE_HANDLERS[carouselType];
    if (!handler) {
      console.debug(`Skipping logging for unhandled carousel type: ${dataElementTypeId}`);
      return;
    }

    logEvent(
      event,
      {
        feature_used: handler.featureUsed(carouselType, data_source_id, event_type, properties),
        feature_category: handler.category(properties),
        feature_subcategory: handler.subcategory(properties),
        feature_location: handler.location(properties),
      },
      handler.getPayload(data_source[previousIndex], previousIndex),
    );
  } catch (error) {
    console.error('Error logging carousel event:', error);
  }
};
