import React from 'react';
import styled from 'styled-components';
import { extend } from 'lodash-es';
import { useNavigate, useMatches, Navigate, Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import './utils/EvolutionGaming';
import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { launchGameUrl } from '../../../api/live-games/gameApi';
import LiveGameIframe, { GameActions } from './utils/LiveGameIframe';
import resolveGameInfo from '../../../utils/game-info-api-cache';
import { reloadWalletsData } from '../../../modules/casino/store/actions/games';

import './index.scss';

type LiveGameLauncherProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const LiveGameLauncherDiv = styled.div<{ $styleText: string }>`
  ${(props) => props.$styleText}
`;

interface FetchGameProps {
  gameCategory: string;
  gameTableId: string;
  authenticated: boolean;
  accessToken: string;
  force?: number;
}
interface FetchGameReturn {
  link?: string;
  error?: Error;
}

const gameThumbnail = (game: any, art_bundle_type: string) => {
  if (game && game.art_bundle && game.art_bundle.data && game.art_bundle.data[art_bundle_type] != null) {
    return game.art_bundle.data[art_bundle_type];
  }
};

const fetchGameId = ({ gameCategory, gameTableId, authenticated, accessToken, force = 0 }: FetchGameProps) => {
  const [launchUrl, setLaunchUrl] = React.useState<FetchGameReturn>({});

  React.useEffect((): any => {
    let stillRelevant = true;

    if (authenticated && accessToken)
      launchGameUrl(gameCategory, gameTableId, accessToken)
        .then((link) => {
          if (!stillRelevant) return;
          if (link) {
            setLaunchUrl({ link });
          } else {
            setLaunchUrl({ error: new Error('Something bad happened') });
          }
        })
        .catch((err) => setLaunchUrl({ error: err }));

    return () => {
      stillRelevant = false;
    };
  }, [gameCategory, gameTableId, authenticated, accessToken, force]);

  return launchUrl;
};

interface IframeMessageProps {
  messageType: GameActions;
  command: GameActions;
  data: any;
}

const LiveGameLauncher = (componentProps: LiveGameLauncherProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matches = useMatches();
  const authentication = useAppSelector((state) => state.authentication);

  // try to initialize game data from the properties object
  let gameTableId = props.properties.gameTableId;
  let gameCategoryId = props.properties.gameCategoryId;

  if (!gameTableId && props.properties.gameTableIdParam) {
    if (matches && matches.length) {
      const match = matches[0];
      if (match.params && match.params[props.properties.gameTableIdParam] != null) {
        gameTableId = match.params[props.properties.gameTableIdParam];
      }
    }
  }

  if (!gameCategoryId && props.properties.gameCategoryIdParam) {
    if (matches && matches.length) {
      const match = matches[0];
      if (match.params && match.params[props.properties.gameCategoryIdParam] != null) {
        gameCategoryId = match.params[props.properties.gameCategoryIdParam];
      }
    }
  }

  const gameCategory = gameCategoryId;

  React.useEffect(() => {
    return () => {
      dispatch(reloadWalletsData());
      try {
        window.EvolutionGaming.removeEventListeners();
      } catch (e) {
        console.error(e);
      }
    };
  }, []);

  const { link, error } = fetchGameId({
    gameCategory,
    gameTableId,
    authenticated: 'user' === authentication.auth_type,
    accessToken: authentication.access_token || '',
  });
  const { data: gameData, loaded: gameDataLoaded } = resolveGameInfo({
    dsId: window.config.dataSourceAllGames,
    gameId: `${gameCategoryId}/${gameTableId}`,
    authenticationToken: authentication?.access_token,
  });

  let uiState = '';
  if (!('user' === authentication.auth_type)) {
    uiState = 'authenticate';
  } else if (!link && !error) {
    uiState = 'loading';
  } else if (error) {
    uiState = 'error';
  } else {
    uiState = 'game';
  }

  React.useEffect(() => {
    if (uiState === 'game') {
      window.EvolutionGaming.init({
        iframeId: 'evolution-game',
        url: link,
        topBar: 48,
        topBarLandscape: 48,
        topBarPortrait: 48,
        allowFullscreen: true,
      });
    }
  }, [uiState]);

  const onIframeMessage = React.useCallback(
    (message: IframeMessageProps) => {
      if (message.command === GameActions.CloseGame) {
        navigate(-1);
      }
    },
    [navigate],
  );

  if (!gameCategory || !gameTableId) return <Navigate to="/" replace />;

  const game = gameDataLoaded && gameData ? gameData.data : null;

  const contextValue = {
    uiState: uiState,
    gameCategory,
    gameTableId,
    name: game?.name ?? '',
    image: game ? gameThumbnail(game, 'landscape_ar32_var_keycenter') : '',
  };

  console.log('LiveGameLauncher[contextValue]', contextValue, gameDataLoaded, gameData);

  return (
    <LiveGameLauncherDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
      {uiState === 'game' && <LiveGameIframe gameUrl={link} onIframeMessage={onIframeMessage} />}
    </LiveGameLauncherDiv>
  );
};

export default LiveGameLauncher;
