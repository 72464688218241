import Utils from '@/modules/casino/utils/Utils';

class PlayOnline {
  static debug = false;
  static saveFields = ['aff_id', 'gclid', 'sub_id', 'moid', 'lpid', 'winner_referral_id', 'btag'];
  static cookieSettings: {
    [key: string]: {
      ttl?: number;
      cookieName?: string;
      secure?: boolean | null;
      sameSite?: string | boolean;
      path?: string;
    };
  } = {
    gclid: { ttl: window.config?.affiliateCookieTtl ?? 2 },
    aff_id: { ttl: window.config?.affiliateCookieTtl ?? 2 },
    moid: { ttl: window.config?.affiliateCookieTtl ?? 2 },
    btag: { ttl: window.config?.affiliateCookieTtl ?? 2, cookieName: 'aff_id', secure: true, sameSite: 'None' },
    default: { ttl: 2, secure: null, sameSite: false, path: '/' },
  };
  static init(debug: boolean) {
    if (debug) {
      if (!this.isActive()) {
        window.config.MarketingEvents.push('PlayOnline not enabled');
      }
    }
    this.debug = debug;
    this.processUrl();
  }

  static processUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    this.saveFields.forEach((field) => {
      if (urlParams.has(field)) {
        const value = urlParams.get(field);
        if (value) {
          Utils.setCookie(
            this.cookieSettings?.[field]?.cookieName ?? field,
            value,
            // @ts-ignore
            parseInt(this.cookieSettings?.[field]?.ttl ?? this.cookieSettings.default.ttl),
            this.cookieSettings?.[field]?.path ?? this.cookieSettings.default.path,
            this.cookieSettings?.[field]?.secure ?? this.cookieSettings.default.secure,
            this.cookieSettings?.[field]?.sameSite ?? this.cookieSettings.default.sameSite,
          );
        }
      }
    });
  }

  static getSavedFields() {
    const fields: { [key: string]: string } = {};
    this.saveFields.forEach((field) => {
      const value = Utils.getCookie(field);
      if (value) {
        fields[field] = value;
      }
    });
    return fields;
  }

  static clearPaid() {
    Utils.setCookie('paid', '', -1);
  }

  static getPaid() {
    let paid = Utils.getCookie('paid');
    if (paid) {
      return paid;
    }

    paid = this.generateUUID();
    this.setPaid(paid);
    return paid;
  }

  static setPaid(paid: string) {
    Utils.setCookie('paid', paid, 2);
  }

  static generateUUID() {
    let d = new Date().getTime(); //Timestamp
    let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }
  static getSavedField(field: string) {
    return Utils.getCookie(field);
  }
  static isActive() {
    return true;
  }
}

export default PlayOnline;
