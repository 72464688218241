import React from 'react';
import styled from 'styled-components';
import IBAN from 'iban';

import { useAppDispatch, useAppSelector } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';

import './index.scss';
import {
  getBetLionWithdrawDetails,
  getPaymentMethods,
  getTax,
  linkIban,
  withdraw,
} from '@/modules/casino/store/actions/withdrawals';
import { paymentProviderNameByWithdrawId, PaymentProviderWithdraw } from '@/constants/paymentProvider';
import { requestDocuments } from '@/modules/casino/store/actions/documents';
import { receivedWithdraw } from '@/modules/casino/store/actions/withdraw_wizard';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { betLionGetChannels } from '@/modules/casino/store/actions/deposit';
import { getCurrencyNumberInfo, getFormattedCurrencyAmount } from '@/page-components/utils/functions';
import { extractNumberFromFormattedString } from '@/components/modules/deposit/functions';

type WithdrawProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  activePaymentProviders: any[];
  withdrawValue: number;
  limitError: string | boolean;
  withdrawValueNoTax: number;
  currentStep: string;
  lastStep: string;
  withdrawType: number;
  totalBonus?: number;
  selectedWithdrawType?: any;
  initialized: boolean;
  paymentMethods: any[];
  error?: string;
  isOpen?: boolean;
  loading?: boolean;
  iban: {
    details: {
      iban: string;
      beneficiaryName: string;
      friendlyName: string;
    };
    errors: {
      iban: boolean;
      beneficiaryName: boolean;
      friendlyName: boolean;
    };
  };
  // currencyPosition: string;
  focusedInput: boolean,
  trigger?: string;
};

interface Hash {
  [key: string]: string;
}

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Withdraw = (componentProps: WithdrawProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const ERRORS = {
    MINIMUM_LIMIT: 'WITHDRAWALS_AMOUNT_ERROR_MINIMUM_LIMIT_{{MIN}}',
    MAXIMUM_LIMIT: 'WITHDRAWALS_AMOUNT_ERROR_MAXIMUM_LIMIT_{{MAX}}',
  };

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const wallet = useAppSelector((state) => state.wallet);
  const freeBets = useAppSelector((state) => state.freeBets.freeBets);
  const { errorCode, transactionId } = useAppSelector((state) => state.withdrawWizard);
  const { betlion,taxes } = useAppSelector((state) => state.withdrawals);
  const profile = useAppSelector((state) => state.profile);
  const documents = useAppSelector((state) => state.documents);
  const paymentMethods = useAppSelector((state) => state.withdrawals.paymentMethods);
  const currencyCode = useAppSelector((state) => state.currencies.currentCurrency);
  // const currency = formatCurrency(currencyCode);
  const currency = (currencyCode);
  // params
  const location = useLocation(); // used with new URLSearchParams() (/deposit?param=value)

  const initialState = {
    activePaymentProviders: [],
    withdrawValue: 0,
    limitError: false,
    withdrawValueNoTax: 0,
    currentStep: 'default',
    lastStep: 'default',
    withdrawType: 0,
    totalBonus: 0,
    selectedWithdrawType: null,
    initialized: false,
    paymentMethods: [],
    error: '',
    isOpen: false,
    loading: false,
    iban: {
      details: {
        iban: '',
        beneficiaryName: '',
        friendlyName: '',
      },
      errors: {
        iban: false,
        beneficiaryName: false,
        friendlyName: false,
      },
    },
    trigger: 'initialState',
    focusedInput: false,
    // currencyPosition: 'left',
  };

  const [state, _setState] = React.useState<ModuleStateProps>(initialState);

  const stateRef = React.useRef(state);

  const setState = (data: any) => {
    stateRef.current = data;
    _setState(data);
  };

  const setActivePaymentProviders = () => {
    const searchParams = new URLSearchParams(location.search);

    let selectedProviders = searchParams.getAll('providerId[]');
    if (selectedProviders.length === 0) {
      // if no providerIds[] in url, add all defined in PaymentProviderWithdraw
      selectedProviders = Object.values(PaymentProviderWithdraw).map((v) => v.toString());
    }

    const activePaymentProviders: any[] = [];

    // intersect selectedProviders with activePaymentProviders
    selectedProviders.forEach((provider) => {
      const providerConfig = window.config?.withdrawSettings?.providers?.[parseInt(provider, 10)];
      if (providerConfig?.isActive === '1') {
        if (parseInt(provider, 10) === PaymentProviderWithdraw.betlion) {
          if(betlion.channels === null) {
            dispatch(betLionGetChannels(1));
          } else {
            betlion.channels.forEach((channel:any) => {
              if(channel.channel_id === 'mobile') {
                activePaymentProviders.push({id:parseInt(provider, 10),info:channel});
              } else if (PaymentProviderWithdraw[`betlion_${channel.channel_id}`]) {
                activePaymentProviders.push({id:PaymentProviderWithdraw[`betlion_${channel.channel_id}`],info:channel});
              }
            });
          }
        } else {
          activePaymentProviders.push({id:parseInt(provider, 10)});
        }

      }
    });

    // sort activePaymentProviders by window.config.withdrawSettings.providers[provider].order
    activePaymentProviders.sort((a, b) => {
      const orderA = window.config?.withdrawSettings?.providers?.[a.id]?.order
        ? parseInt(window.config.withdrawSettings.providers[a.id].order)
        : 9999;
      const orderB = window.config?.withdrawSettings?.providers?.[b.id]?.order
        ? parseInt(window.config.withdrawSettings.providers[b.id].order)
        : 9999;
      return orderA - orderB;
    });

    setState((v: any) => ({
      ...v,
      activePaymentProviders: activePaymentProviders,
      trigger:'setActivePaymentProviders'
    }));
  };

  const getMaxLimit = () => {
    const max: any =
      window.config.withdrawSettings?.providers?.[state.withdrawType]?.defaultLimits?.[currencyCode]?.max ??
      window.config?.withdrawSettings?.defaultLimits?.[currencyCode]?.max ??
      0;

    return max > wallet.main ? wallet.main : max;
  };

  const getMinLimit = () => {
    return (
      window.config?.withdrawSettings?.providers?.[state.withdrawType]?.defaultLimits?.[currencyCode]?.min ??
      window.config?.withdrawSettings?.defaultLimits?.[currencyCode]?.min ??
      0
    );
  };

  React.useEffect(() => {
    setState((v: any) => ({
      ...v,
      ...initialState,
      trigger: 'init-useEffect-reset',
    }));
    if(window.config?.withdrawSettings?.defaultAmount?.[currencyCode]) {
      withdrawValueChangeFunction(window.config?.withdrawSettings?.defaultAmount?.[currencyCode]);
    }
    dispatch(receivedWithdraw({ reset: true }));
    // dispatch(requestBPCardToken());
    if (profile?.client_player_id) {
      init();
    }

    // setCurrencyPosition();

    return () => {
      dispatch(receivedWithdraw({ reset: true }));
    };
  }, []);

  React.useEffect(() => {
    if (profile?.client_player_id) {
      init();
    }
  }, [profile]);

  // React.useEffect(() => {
  //   setCurrencyPosition();
  // }, [currencyCode]);

  // const setCurrencyPosition = () => {
  //   const numberCurrency = getCurrencyNumberInfo({locale:'',amount: 1,digits:'',currency: currencyCode});
  //
  //   setState((v: any) => ({
  //       ...v,
  //       currencyPosition: numberCurrency.types?.[0] === 'a' ? 'right' : 'left',
  //       trigger: 'setCurrencyPosition',
  //   }));
  // };

  React.useEffect(() => {
    let documentsOK = true;
    const ignoreDocuments = window.config?.withdrawSettings?.ignoreDocuments === '1';
    if (documents && !ignoreDocuments) {
      if (documents.documentsRequest) {
        const docs = documents.documentsRequest;
        if (docs.length > 0) {
          for (let i = 0; i < docs.length; i++) {
            if (docs[i].status !== 8 /*&& docs[i].type === 1*/) {
              documentsOK = false;
              break;
            }
          }
        }
      }
    }
    if (!documentsOK) {
      setState((v: any) => ({
        ...v,
        currentStep: 'documents',
        lastStep: v.currentStep,
        trigger: 'documentsOK-false',
      }));
    }
  }, [documents]);

  React.useEffect(() => {
    if (betlion?.channels) {
      setActivePaymentProviders();
    }
  },[betlion.channels]);

  React.useEffect(() => {

    let pm: any[] = [];
    const iban = state.iban.details.iban.replace(/\s/g, '');
    if (paymentMethods.length > 0) {
      pm = paymentMethods.reduce((acc, item) => {
        const type: number = getWithdrawType(item);
        let exists:any = false;

        state.activePaymentProviders.forEach((provider) => {
          if (provider.id === type) {
            exists = provider;
          }
        });
        if (!exists) {
          return acc;
        }

        if (!acc[type]) {
          acc[type] = {
            type: type,
            details: [],
            info: exists.info
          };
        }

        acc[type].details.push({
          ...item.withdrawMethodDetails,
          reference: item.withdrawTypeReference,
        });

        if (type === PaymentProviderWithdraw.iban && iban) {
          if (item.withdrawMethodDetails?.iban === iban) {
            setState((v: any) => ({
              ...v,
              selectedWithdrawType: item.withdrawTypeReference,
              trigger: 'iban-match',
            }));
          }
        }
        return acc;
      }, []);
    }

    const sortedPM: any[] = [];

    state.activePaymentProviders.forEach((provider) => {
      if (pm[provider.id]) {
        if (pm[provider.id]?.details?.length > 0) {
          sortedPM.push({ ...pm[provider.id], add: true, info: provider.info });
        } else if (window.config?.withdrawSettings?.providers?.[provider.id]?.showAlways === '1') {
          sortedPM.push({ ...pm[provider.id], add: true, info: provider.info });
        }
      } else if (window.config?.withdrawSettings?.providers?.[provider.id]?.showAlways === '1') {
        sortedPM.push({
          type: provider.id,
          details: [],
          add: true,
          info: provider.info
        });
      }
    });
    setState((v: any) => ({
      ...v,
      paymentMethods: sortedPM,
        trigger: 'paymentMethods-sorted',
    }));

    if (state.currentStep === 'default') {
      let newProvider = null;
      if (sortedPM.length > 0) {
        newProvider = sortedPM[0].type;
      }
      changePaymentProvider(newProvider);
    }
  }, [paymentMethods,state.activePaymentProviders]);

  React.useEffect(() => {
    let totalBonus = wallet.bonus ? wallet.bonus : 0;
    freeBets.forEach((fb) => {
      totalBonus += fb.count * (fb.amount_small === 1 ? fb.amount / 100 : fb.amount);
    });

    if (totalBonus > 0) {
      setState((v: any) => ({
        ...v,
        currentStep: 'bonus-active',
        lastStep: v.currentStep,
        totalBonus: totalBonus,
        trigger: 'bonus-active',
      }));
    } else {
      setState((v: any) => ({
        ...v,
        totalBonus: totalBonus,
        trigger: 'bonus-inactive',
      }));
    }
  }, [wallet, freeBets]);

  React.useEffect(() => {
    if(state.currentStep === 'betlion') {
      withdrawBetlion();
    } else if ((state.currentStep === 'taxes' && ((taxes[state.withdrawValue] == 0 || typeof taxes[state.withdrawValue] === 'undefined') || window.config?.withdrawSettings?.skipTaxStep === '1'))) {
      acceptTaxes();
    }
  }, [state.currentStep]);

  React.useEffect(() => {
    if (errorCode) {
      setState((v: any) => ({
        ...v,
        error: errorCode === null ? '' : errorCode,
        trigger: 'errorCode-useEffect',
      }));
    }
  }, [errorCode]);

  React.useEffect(() => {
    if (state.error !== '') {
      if (state.error) {
        setState((v: any) => ({
          ...v,
          currentStep: 'error',
          lastStep: v.currentStep,
          loading: false,
          trigger: 'found_state.error-useEffect',
        }));
      } else {
        setState((v: any) => ({
          ...v,
          currentStep: v.currentStep === 'add-bank-transfer' ? 'bank-transfer' : 'success',
          lastStep: v.currentStep,
          loading: false,
          error: '',
            trigger: 'no-state.error-useEffect',
        }));
      }
    }
  }, [state.error]);

  React.useEffect(() => {
    if (transactionId) {
      if (!errorCode) {
        setState((v: any) => ({
          ...v,
          currentStep: 'success',
          lastStep: v.currentStep,
          loading: false,
          trigger: 'transactionId-useEffect',
        }));
      }
    }
  }, [transactionId]);

  React.useEffect(() => {
    if (betlion.withdraw_reference_id) {
      setState((v: any) => ({
        ...v,
        selectedWithdrawType: betlion.withdraw_reference_id,
        trigger: 'betlion.withdraw_reference_id-useEffect',
        loading: true,
      }));
      dispatch(withdraw(state.withdrawValue, PaymentProviderWithdraw.betlion, betlion.withdraw_reference_id));
    }
  },[betlion.withdraw_reference_id]);

  React.useEffect(() => {
    setState((v: any) => ({
      ...v,
      limitError: getLimitError(state.withdrawValue),
      trigger: 'wallet-useEffect',
    }));
  },[wallet,state.withdrawValue]);

  const getSelectedPaymentMethod = (providerType:any = false) => {
    if(providerType) {
      return state.paymentMethods.find((item) => item.type === providerType);
    }
    return state.paymentMethods.find((item) => item.type === state.withdrawType);
  };

  const withdrawBetlion = () => {
    // get payment method by type
    const paymentMethod = getSelectedPaymentMethod();
    if(!paymentMethod) {
      return;
    }
    if (paymentMethod.details.length === 1) {
      setState((v: any) => ({
        ...v,
        selectedWithdrawType: paymentMethod.details[0].reference,
        trigger: 'withdrawBetlion-selectedWithdrawType',
        loading: true,
      }));
      dispatch(withdraw(state.withdrawValue, PaymentProviderWithdraw.betlion, paymentMethod.details[0].reference));
    } else {
      setState((v: any) => ({
        ...v,
        trigger: 'withdrawBetlion-else',
        loading: true,
      }));
      dispatch(getBetLionWithdrawDetails({ channelId: paymentMethod.info.channel_id }));
    }
    // get payment method details
  };

  const getWithdrawType = (item: any) => {

    const type = parseInt(item.withdrawType,10);
    if (type === PaymentProviderWithdraw.betlion) {
        if (item.withdrawMethodDetails?.channelId === 'mobile') {
          return PaymentProviderWithdraw.betlion;
        } else {
          if (PaymentProviderWithdraw[`betlion_${item.withdrawMethodDetails?.channelId}`]) {
            return PaymentProviderWithdraw[`betlion_${item.withdrawMethodDetails?.channelId}`];
          } else {
            return 0;
          }
        }
    }
    return type;
  };

  const init = () => {
    if (state.initialized) {
      return;
    }
    setState((v: any) => ({
      ...v,
      initialized: true,
      trigger: 'init',
    }));


      setActivePaymentProviders();
    if (profile?.verified) {
      dispatch(getPaymentMethods());
      dispatch(requestDocuments());

    } else {
      const ignoreKYC = window.config?.withdrawSettings?.ignoreKYC === '1';
      if (!ignoreKYC) {
        setState((v: any) => ({
          ...v,
          currentStep: 'kyc',
          lastStep: v.currentStep,
          trigger: 'init-KYC',
        }));
      }
    }
  };

  const acceptTaxes = () => {
    const st = {
      selectedWithdrawType: state.paymentMethods[state.withdrawType]?.details?.[0]?.reference,
    };
    // @ts-ignore
    switch (parseInt(state.withdrawType, 10)) {
      case PaymentProviderWithdraw.bridger:
        setState((v: any) => ({
          ...v,
          ...st,
          currentStep: 'bridger',
          lastStep: v.currentStep,
          modalOpen: false,
          trigger: 'acceptTaxes-bridger',
        }));
        break;
      case PaymentProviderWithdraw.viva:
        setState((v: any) => ({
          ...v,
          ...st,
          currentStep: 'viva',
          lastStep: v.currentStep,
          modalOpen: false,
          trigger: 'acceptTaxes-viva',
        }));
        break;
      case PaymentProviderWithdraw.iban:
        setState((v: any) => ({
          ...v,
          ...st,
          currentStep: 'bank-transfer',
          lastStep: v.currentStep,
          modalOpen: false,
          trigger: 'acceptTaxes-iban',
        }));
        break;
      case PaymentProviderWithdraw.skrill:
        setState((v: any) => ({
          ...v,
          ...st,
          currentStep: 'skrill',
          lastStep: v.currentStep,
          modalOpen: false,
          trigger: 'acceptTaxes-skrill',
        }));
        break;
      case PaymentProviderWithdraw.betlion:
      case PaymentProviderWithdraw.betlion_unitel:
      case PaymentProviderWithdraw.betlion_telopay:
      case PaymentProviderWithdraw.betlion_afrimoney:
      case PaymentProviderWithdraw.betlion_ekwanza:
      case PaymentProviderWithdraw.betlion_aki:
      case PaymentProviderWithdraw.betlion_paypay:
      case PaymentProviderWithdraw.betlion_atlantico:
      case PaymentProviderWithdraw.betlion_other_bank_account:

        setState((v: any) => ({
          ...v,
          ...st,
          currentStep: 'betlion',
          lastStep: v.currentStep,
          modalOpen: false,
          trigger: 'acceptTaxes-betlion',
        }));
        break;
      default:
        break;
    }
  };

  const onAmountButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const el = e.currentTarget ?? (e.currentTarget as HTMLButtonElement);
    // get data-value from button
    const value = el.getAttribute('data-value');
    if (value) {
      withdrawValueChangeFunction(value);
    }
  };

  const withdrawValueChangeFunction = (value: any) => {
    
    setState((v: any) => ({
      ...v,
      withdrawValue: value,
      limitError: getLimitError(value),
      trigger: 'withdrawValueChangeFunction',
    }));
    if(typeof taxes[value] === 'undefined') {
      dispatch(getTax(value)); // get tax
    }
  };

  const getLimitError = (value: number) => {
    const min: any = getMinLimit();
    const max = getMaxLimit();
    // @ts-ignore
    return value < min ? ERRORS.MINIMUM_LIMIT : value > max ? `${ERRORS.MAXIMUM_LIMIT}` : false;
  };

  const onChangeWithdrawValue = (e: React.ChangeEvent<HTMLInputElement>) => {

    // const newValue = e.target.value === '' ? 0 : (state.focusedInput ? e.target.value?.replace(/[^0-9.]/g,'') : extractNumberFromFormattedString(e.target.value)) || state.withdrawValue;

    let newValue:number = 0;/*e.target.value === '' ? 0 : (state.focusedInput ? e.target.value?.replace(/[^0-9.]/g,'') : extractNumberFromFormattedString(e.target.value)) || state.depositValue;*/
    if (e.target.value !== '') {
      if(state.focusedInput) {
        newValue = parseFloat(e.target.value?.replace(/[^0-9.]/g,''));
      } else {
        newValue = extractNumberFromFormattedString(e.target.value);
      }
    }

    if (newValue === state.withdrawValue) {
      return;
    }
    withdrawValueChangeFunction(newValue);
  };

  const getButtonValues = () => {
    const values = window.config?.withdrawSettings?.defaultPresets?.[currencyCode] ?? [20, 50, 100, 200, 300, 500];
    const hideCurrency = window.config?.withdrawSettings?.hidePresetsCurrency === '1';
    return values.map((value: number) => {
      const renderedValue = getFormattedCurrencyAmount({locale:'',amount: value,digits:'',currency: hideCurrency ? '' : currencyCode});

      return {
        value: value,
        // valueRendered: `${value} ${currency}`,
        valueRendered: `${renderedValue}`,
        id: value,
        onClickHandler: onAmountButtonClick,
        active: state.withdrawValue == value,
        className: 'buttons-grid-3',
        description: '',
      };
    });
  };

  const changePaymentProvider = (withdrawType: number) => {
    if (state.withdrawType == withdrawType) {
      // setState({
      //   ...state,
      //   isOpen: !state.isOpen,
      //   trigger: 'changePaymentProvider-isOpen',
      // });
      return;
    }
    setState((v: any) => ({
      ...v,
      withdrawType: withdrawType,
      isOpen: true,
      trigger: 'changePaymentProvider',
    }));
  };

  const confirmValue = () => {
    setState((v: any) => ({
      ...v, 
      currentStep: 'taxes',
      lastStep: v.currentStep,
      trigger: 'confirmValue',
    }));
  };

  const onWithdraw = () => {
    setState((v: any) => ({
      ...v,
      loading: true,
      trigger: 'onWithdraw',
    }));
    if (state.currentStep === 'add-bank-transfer') {
      // iban without spaces
      const iban = state.iban?.details?.iban?.replace(/\s/g, '') ?? '';

      dispatch(linkIban(iban, state.iban.details.beneficiaryName, state.iban.details.friendlyName));
    } else {
      dispatch(withdraw(state.withdrawValue, state.withdrawType, state.selectedWithdrawType));
    }
  };

  const getPaymentProviderDetails = () => {
    const data: any = [];
    // @ts-ignore
    const type = parseInt(state.withdrawType, 10);
    // search for type in paymentMethods
    const foundIndex = state.paymentMethods.findIndex((item) => item.type === type);

    state.paymentMethods[foundIndex]?.details?.forEach((item: any) => {
      switch (type) {
        case PaymentProviderWithdraw.viva:
        case PaymentProviderWithdraw.bridger:
          let cardNumber = item.cardNumber;
          // format cardNumber to be displayed as **** **** **** 1234
          const cardNumberRaw = cardNumber?.replace(/\s/g, '');
          if (cardNumberRaw?.length === 16) {
            // replace first chars with *
            cardNumber = cardNumberRaw?.replace(/.{12}/, '**** **** **** ') ?? '';
          }

          if (cardNumber === '') {
            cardNumber = '**** **** **** ****';
          }

          data.push({
            name: cardNumber,
            reference: item.reference,
            description: item.cardIssuingBank,
            verified: item.verified,
            selected: state.selectedWithdrawType === item.reference,
            onClick: () => {
              setState((v: any) => ({
                ...v,
                selectedWithdrawType: item.reference,
                trigger: 'getPaymentProviderDetails-viva-bridger',
              }));
            },
          });
          break;
        case PaymentProviderWithdraw.iban:
          data.push({
            name: item.friendlyName,
            reference: item.reference,
            description: item.iban,
            selected: state.selectedWithdrawType === item.reference,
            onClick: () => {
              setState((v: any) => ({
                ...v,
                selectedWithdrawType: item.reference,
                trigger: 'getPaymentProviderDetails-iban',
              }));
            },
          });
          break;
        case PaymentProviderWithdraw.skrill:
          data.push({
            name: item.email,
            reference: item.reference,
            description: '',
            selected: state.selectedWithdrawType === item.reference,
            onClick: () => {
              setState((v: any) => ({
                ...v,
                selectedWithdrawType: item.reference,
                trigger: 'getPaymentProviderDetails-skrill',
              }));
            },
          });
          break;
      }
    });

    return data;
  };

  const onInputFocus = () => {
    setState((v: any) => ({
      ...v,
      focusedInput: true,
      trigger: 'onInputFocus',
    }));
  };

  const onInputBlur = () => {
    setState((v: any) => ({
      ...v,
      focusedInput: false,
      // @ts-ignore
      withdrawValue: parseFloat(parseFloat(state.withdrawValue).toFixed(2)), // double parsing to ensure that we have maximum 2 decimal places
      trigger: 'onInputBlur',
    }));
  };

  let buttonDisabled = state.withdrawValue === 0 || state.error !== '' || state.limitError || state.focusedInput || state.loading;
  if (state.currentStep !== 'default') {
    switch (state.currentStep) {
      case 'add-bank-transfer':
        buttonDisabled =
          !state.iban.details.iban ||
          !state.iban.details.beneficiaryName ||
          state.iban.errors.iban ||
          state.iban.errors.beneficiaryName;
        break;
      default:
        buttonDisabled = !state.selectedWithdrawType || state.selectedWithdrawType === 'undefined';
        break;
    }
  }
  const max = getFormattedCurrencyAmount({locale:'',amount: getMaxLimit(),digits:'',currency: currencyCode});
  const min = getFormattedCurrencyAmount({locale:'',amount: getMinLimit(),digits:'',currency: currencyCode});

  const translatePlaceholders= {
    MAX: max,
    MIN: min,
  };

  const withdrawValue = state.focusedInput ? state.withdrawValue : getCurrencyNumberInfo({locale:'',amount: state.withdrawValue,digits:'',currency: currencyCode}).amount;
  
  const contextValue = {
    ...state,
    walletAmount: wallet?.main,
    walletTotal: wallet?.total,
    walletBonus: wallet?.bonus,
    totalBonusAmount: state.totalBonus + ` ${currency}`,
    currency: currency,
    taxAmount: taxes[state.withdrawValue] ? taxes[state.withdrawValue] + ` ${currency}` : false,
    onChangeWithdrawValue: onChangeWithdrawValue,
    modalOpen: true,

    onBackHandler: () => {
      let backStep: string;
      switch (state.currentStep) {
        case 'add-bank-transfer':
          backStep = 'bank-transfer';
          break;
        default:
          backStep = 'default';
          break;
      }

      setState((v: any) => ({
        ...v,
        currentStep: backStep,
        lastStep: v.currentStep,
        trigger: 'onBackHandler',
      }));
    },

    acceptTaxesHandler: () => {
      acceptTaxes();
    },
    onToggleEmptyFn: () => {
      // empty function
    },
    paymentProviderList: state.paymentMethods.map((provider) => {

      const options: { channel?:string; } = {};
      
      switch (provider.type) {
        case PaymentProviderWithdraw.betlion:
        case PaymentProviderWithdraw.betlion_unitel:
        case PaymentProviderWithdraw.betlion_telopay:
        case PaymentProviderWithdraw.betlion_afrimoney:
        case PaymentProviderWithdraw.betlion_ekwanza:
        case PaymentProviderWithdraw.betlion_aki:
        case PaymentProviderWithdraw.betlion_paypay:
        case PaymentProviderWithdraw.betlion_atlantico:
        case PaymentProviderWithdraw.betlion_other_bank_account:
          options['channel'] = provider.info.channel_id;
      }

      return {
        id: provider.paymentProviderId,
        currentStep: `amount-${paymentProviderNameByWithdrawId[provider.type]?.toLowerCase()}`,
        buttons: getButtonValues(),
        selectedBonusInfo: null,
        bonusCounter: 0,
        taxValue: taxes[state.withdrawValue] ? taxes[state.withdrawValue] : 0,
        currency: currency,
        value: withdrawValue,
        error: state.limitError ?? state.error,
        onValueChange: onChangeWithdrawValue,
        onAmountInputFocus: onInputFocus,
        onAmountInputBlur: onInputBlur,
        // currencyPosition: state.currencyPosition,
        changePaymentProvider: () => {
          changePaymentProvider(provider.type);
        },
        isOpen: state.withdrawType == provider.type, //&& state.isOpen,
        inputAmountDisabled: false,
        confirmButton: {
          state: buttonDisabled ? 'disabled' : 'default',
          colorMapping: buttonDisabled ? 'inverted' : 'primary',
          buttonType: 'filled',
          size: 'small',
          style: 'text',
          shadow: buttonDisabled ? 'none' : 'depth_2',
          loading: state.loading,
          ctaText: 'Withdraw',
          icon: '',
          onClick: buttonDisabled ? () => 0 : confirmValue,
        },
        options: options,
        translatePlaceholders: translatePlaceholders,
      };
    }),
    paymentProviderDetails: getPaymentProviderDetails(),
    onWithdraw: buttonDisabled ? () => 0 : onWithdraw,
    addNewIban: () => {
      setState((v: any) => ({
        ...v,
        currentStep: 'add-bank-transfer',
        lastStep: v.currentStep,
        trigger: 'addNewIban',
      }));
    },
    iban: state.iban.details.iban,
    beneficiaryName: state.iban.details.beneficiaryName,
    friendlyName: state.iban.details.friendlyName,
    ibanError: state.iban.errors.iban,
    beneficiaryNameError: state.iban.errors.beneficiaryName,
    friendlyNameError: state.iban.errors.friendlyName,
    onBeneficiaryNameChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value;
      // check if value is from minimum 2 words
      if (!value.match(/^[0-9a-zA-Z-\s'.]*$/) || value.split(' ').length < 2) {
        setState((v: any) => ({
          ...v,
          iban: {
            ...v.iban,
            errors: {
              ...v.iban.errors,
              beneficiaryName: 'IBAN_INVALID_BENEFICIARY_NAME',
              iban: v.iban.details.iban ? v.iban.errors.iban : false,
            },
            details: {
              ...v.iban.details,
              beneficiaryName: value,
            },
          },
          trigger: 'onBeneficiaryNameChange',
        }));
      } else {
        setState((v: any) => ({
          ...v,
          iban: {
            ...v.iban,
            errors: {
              ...v.iban.errors,
              beneficiaryName: false,
              iban: v.iban.details.iban ? v.iban.errors.iban : false,
            },
            details: {
              ...v.iban.details,
              beneficiaryName: value,
            },
          },
          trigger: 'onBeneficiaryNameChange',
        }));
      }
    },
    onFriendlyNameChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value;
      // check if value is from  minimum 2 characters
      if (!/^.{2,}$/.test(value)) {
        setState((v: any) => ({
          ...v,
          iban: {
            ...v.iban,
            errors: {
              ...v.iban.errors,
              friendlyName: 'IBAN_INVALID_FRIENDLY_NAME',
            },
          },
          trigger: 'onFriendlyNameChange',
        }));
      }
    },
    onIbanChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value?.replace(/[^a-zA-Z0-9]/g, '');
      if (!IBAN.isValid(value)) {
        setState((v: any) => ({
          ...v,
          iban: {
            ...v.iban,
            details: {
              ...v.iban.details,
              iban: value,
            },
            errors: {
              ...v.iban.errors,
              iban: 'INVALID_IBAN',
              beneficiaryName: v.iban.details.beneficiaryName ? v.iban.errors.beneficiaryName : false,
            },
          },
          trigger: 'onIbanChange',
        }));
      } else {
        setState((v: any) => ({
          ...v,
          iban: {
            ...v.iban,
            details: {
              ...v.iban.details,
              iban: IBAN.printFormat(value),
            },
            errors: {
              ...v.iban.errors,
              iban: false,
            },
          },
          trigger: 'onIbanChange',
        }));
      }
    },
    addIbanButton: {
      state: buttonDisabled ? 'disabled' : 'default',
      colorMapping: buttonDisabled ? 'inverted' : 'primary',
      buttonType: 'filled',
      size: 'small',
      style: 'text',
      shadow: buttonDisabled ? 'none' : 'depth_2',
      loading: state.loading,
      ctaText: 'Add IBAN',
      icon: '',
      onClick: buttonDisabled ? () => 0 : onWithdraw,
    },

    confirmButton: {
      state: buttonDisabled ? 'disabled' : 'default',
      colorMapping: buttonDisabled ? 'inverted' : 'primary',
      buttonType: 'filled',
      size: 'small',
      style: 'text',
      shadow: buttonDisabled ? 'none' : 'depth_2',
      loading: state.loading,
      ctaText: 'Deposit',
      icon: '',
      onClick: buttonDisabled ? () => 0 : onWithdraw,
    },
    retryFn: () => {
      dispatch(receivedWithdraw({ reset: true }));
      setState((v: any) => ({
        ...v,
        currentStep: v.lastStep !== 'error' && v.lastStep !== 'success' ? v.lastStep : 'default',
        lastStep: v.currentStep,
        loading: false,
        trigger: 'retryFn',
      }));
    },
    translatePlaceholders: translatePlaceholders,
  };
  React.useEffect(() => {
    // console.log(state.trigger,state);
  }, [state.withdrawValue]);
  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Withdraw;