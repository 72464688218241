import i18next from 'i18next';
import { processTranslatableText } from '@/page-components/utils/functions';

export type PasswordValidatorPlaceholders = {
  // [key: string]: string;
  ERROR_MESSAGE: string;
};

const PasswordValidator = (
  password: string,
  t: any,
  config?: {
    minimum_length: string;
    maximum_length: string;
    medium_length: string;
    long_length: string;
    uppercase: string;
    lowercase: string;
    digit: string;
    special: string;
    letter: string;
    labels: any[];
    restrictSpecialCharacters: string;
  },
) => {
  if (!config) {
    config = window.config.password;
  }
  const {
    labels,
    minimum_length,
    maximum_length,
    uppercase,
    lowercase,
    digit,
    special,
    medium_length,
    long_length,
    letter,
    restrictSpecialCharacters,
  } = config;

  const parsedMinimumLength = parseInt(minimum_length, 10);
  const parsedMaximumLength = parseInt(maximum_length, 10);
  const parsedMediumLength = parseInt(medium_length, 10);
  const parsedLongLength = parseInt(long_length, 10);
  const parsedUppercase = uppercase === '1' ? true : false;
  const parsedLowercase = lowercase === '1' ? true : false;
  const parsedDigit = digit === '1' ? true : false;
  const parsedSpecial = special === '1' ? true : false;
  const parsedLetter = letter === '1' ? true : false;
  const parsedRestrictSpecialCharacters = restrictSpecialCharacters === '1' ? true : false;

  // score based on required fields from config
  let score = 0;
  // total score based on all possible fields from config
  let totalScore = 0;

  // maximum score represents the total number of possible requirements, not only the required ones
  // TODO: increase XXX in (Object.keys(config).length - XXX) when you add a new config key to the password object that should not be counted as a requirement
  // const maxScoreLimit = Object.keys(config).length - 2; // ignore labels and maximum_length
  const maxScoreLimit = Object.keys(config).length - 3; // ignore labels , maximum_length and restrictSpecialCharacters
  // list of conditions not met
  const conditionsNotMet = [];
  // ignore minimum_length and medium_length; this will decrease in the next lines of code
  let minimumScoreRequired = maxScoreLimit - 2;
  // check length
  if (password.length >= parsedMinimumLength && password.length <= parsedMaximumLength) {
    score++;
    totalScore++;
    if (password.length >= parsedMediumLength) {
      totalScore++;
    }
    if (password.length >= parsedLongLength) {
      totalScore++;
    }
  } else {
    conditionsNotMet.push('length');
  }

  // check uppercase
  if (!parsedUppercase) {
    minimumScoreRequired--;
  }
  if (/[A-Z]/.test(password)) {
    totalScore++;
    if (parsedUppercase) {
      score++;
    }
  } else {
    if (parsedUppercase) {
      conditionsNotMet.push('uppercase');
    }
  }

  // check letter
  if (!parsedLetter) {
    minimumScoreRequired--;
  }
  if (/[A-Za-z]/.test(password)) {
    totalScore++;
    if (parsedLetter) {
      score++;
    }
  } else {
    if (parsedLetter) {
      conditionsNotMet.push('letter');
    }
  }

  // check lowercase
  if (!parsedLowercase) {
    minimumScoreRequired--;
  }
  if (/[a-z]/.test(password)) {
    totalScore++;
    if (parsedLowercase) {
      score++;
    }
  } else {
    if (parsedLowercase) {
      conditionsNotMet.push('lowercase');
    }
  }

  // check digit
  if (!parsedDigit) {
    minimumScoreRequired--;
  }
  if (/[0-9]/.test(password)) {
    totalScore++;
    if (parsedDigit) {
      score++;
    }
  } else {
    if (parsedDigit) {
      conditionsNotMet.push('digit');
    }
  }

  // check special characters
  if (!parsedSpecial || parsedRestrictSpecialCharacters) {
    minimumScoreRequired--;
  }

  if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
    totalScore++;
    if (parsedSpecial) {
      score++;
    }
  } else {
    if (parsedSpecial && !parsedRestrictSpecialCharacters) {
      conditionsNotMet.push('special');
    }
  }

  let finalScore = Math.floor((totalScore / maxScoreLimit) * 100);

  // minimum score should not be 0
  // this is to prevent the password strength meter from showing the CMS default values
  if (finalScore == 0) {
    finalScore = 0.001;
  }

  // OBS: backend trims the password so we need to check if the password contains empty spaces
  const containsEmptySpace = password.includes(' ');
  if (containsEmptySpace) {
    conditionsNotMet.push('empty_space');
  }

  const passwordAccepted = score >= minimumScoreRequired;
  let shortLabel = labels?.[0]?.label ?? 'Labels not found.';
  labels.forEach((label) => {
    if (finalScore >= label.minimum_score) {
      shortLabel = label.label;
    }
  });
  const longLabel = `Password strength is ${shortLabel.toLowerCase()}.`; // used as placeholder for the message

  // let message = 'Password must contain:';
  let message = longLabel;

  const placeholders = {
    ERROR_MESSAGE: '',
  };

  if (conditionsNotMet.length > 0) {
    const errorsFound = [];
    for (const cond of conditionsNotMet) {
      if (cond === 'letter') errorsFound.push(t('PASSWORD_VALIDATOR.LETTER'));
      if (cond === 'lowercase') errorsFound.push(t('PASSWORD_VALIDATOR.LOWERCASE'));
      if (cond === 'uppercase') errorsFound.push(t('PASSWORD_VALIDATOR.UPPERCASE'));
      if (cond === 'digit') errorsFound.push(t('PASSWORD_VALIDATOR.DIGIT'));
      if (cond === 'special') errorsFound.push(t('PASSWORD_VALIDATOR.SPECIAL'));
      if (cond === 'empty_space') errorsFound.push(t('PASSWORD_VALIDATOR.EMPTY_SPACE'));
      if (cond === 'length') {
        errorsFound.push(
          processTranslatableText(
            'PASSWORD_VALIDATOR.LENGTH:{{MIN_PASSWORD_LENGTH}}{{MAX_PASSWORD_LENGTH}}',
            {
              MIN_PASSWORD_LENGTH: parsedMinimumLength,
              MAX_PASSWORD_LENGTH: parsedMaximumLength,
            },
            i18next.language,
            t,
          ),
        );
      }
    }

    if (errorsFound.length > 0) {
      placeholders.ERROR_MESSAGE = errorsFound.join(', ');
      message = 'PASSWORD_VALIDATOR.ERROR_MESSAGE:{{ERROR_MESSAGE}}';
    } else {
      message = longLabel;
    }
  }

  // console.log('DEBUG [PasswordValidator]:', {
  //   score: finalScore,
  //   success: passwordAccepted && !containsEmptySpace,
  //   conditionsNotMet: conditionsNotMet,
  //   message: message,
  //   placeholders: placeholders,
  //   labels: {
  //     short: shortLabel,
  //     long: longLabel,
  //   },
  // });

  return {
    score: finalScore,
    success: passwordAccepted && !containsEmptySpace,
    conditionsNotMet: conditionsNotMet,
    message: message,
    placeholders: placeholders,
    labels: {
      short: shortLabel,
      long: longLabel,
    },
  };
};

export default PasswordValidator;
