import {
  validateFieldResponse,
  sendRegisterSMSResponse,
  registerResponse,
  partialAccountResponse,
  setRegisterSignUpLoading,
} from '../actions/register';
import ClientAPI from './../../ClientAPI/ClientAPI';
import { put } from 'redux-saga/effects';
import { validation, register } from '../../ClientAPI/paths';
import PlayOnline from '@/utils/marketing/Providers/PlayOnline';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker.js';
import ResponseErrorMessages from '@/modules/casino/store/errors/ResponseErrorMessages.js';

const parameters = {
  email: 'mail',
  phone: 'phone',
  cnp: 'cnp',
  nin: 'cnp',
  nickname: 'nickname',
};
export function* validateFieldSaga(action) {
  const axios = ClientAPI.getInstance();

  try {
    let data = {};
    data[parameters[action.field]] = action.value;

    const response = yield axios({
      url: validation[action.field],
      method: 'post',
      data: data,
    });

    // console.log('DEBUG [validateFieldSaga] response', response);

    if (!response.status) {
      throw new Error(`[ERROR] ${action.field} Validation missing status!`);
    }

    if (!('OK' === response.status)) {
      throw new Error(`[ERROR] ${action.field} Validation not triggered!`);
    }

    if (response.result && response.result.http) {
      throw new Error(`[ERROR] ${ResponseErrorMessages.get(1)}`);
    }

    yield put(validateFieldResponse(response.result, action.field));
  } catch (error) {
    console.log(error);
  }
}

export function* sendRegisterSMSSaga(action) {
  const axios = ClientAPI.getInstance();
  const postData =
    action.data && typeof action.data === 'string'
      ? {
          phone: action.data,
        } // for RO phone number
      : {
          phone: action.data.phone,
          phone_country_code: action.data.phone_country_code,
        }; // for international phone numbers

  // console.log('DEBUG [sendRegisterSMSSaga] PAYLOAD', { action, postData });

  try {
    const response = yield axios({
      url: register.SEND_REGISTER_SMS,
      method: 'post',
      // data: postData,
      data: action.data,
    });

    // console.log('DEBUG [sendRegisterSMSSaga] RESPONSE', response);

    if (!response.status) {
      throw new Error('[ERROR] SMS not sent!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] SMS not sent!');
    }

    if (response.result && response.result.http) {
      throw new Error(`[ERROR] ${ResponseErrorMessages.get(1)}`);
    }

    yield put(sendRegisterSMSResponse(response.result));
  } catch (error) {
    console.log(error);
  }
}

export function* registerSaga(action) {
  const axios = ClientAPI.getInstance();
  const { data } = action;
  let pac = PlayerAbuseChecker.getInfo();

  // console.log('DEBUG [registerSaga] PAYLOAD', { action, pac });

  try {
    const response = yield axios({
      url: register.REGISTER,
      method: 'post',
      data: {
        ...pac,
        ...data,
      },
    });

    // console.log('DEBUG [registerSaga] RESPONSE', response);

    if (!response.status) {
      throw new Error('[ERROR] Register not sent!');
    }

    if (response.result && response.result.http) {
      throw new Error(`[ERROR] ${ResponseErrorMessages.get(1)}`);
    }

    yield put(registerResponse(response.result));
    yield put(setRegisterSignUpLoading(false));
  } catch (error) {
    yield put(registerResponse(false));
    yield put(setRegisterSignUpLoading(false));
  }
}

export function* setPartialAccountSaga(action) {
  const axios = ClientAPI.getInstance();

  const postData = {
    ...action.data,
    // email: 'fake@nodomain.com',
  };

  // if (!postData.email && postData.phone_country_code !== 'RO') {
  //   // TODO: replace with a list of countries that don`t require email
  //   postData.email = window.location.hostname.replace('www.', '');
  //   if (postData.email.indexof('.') === -1) postData.email = 'nodomain.com';
  //   postData.email = postData.phone + '@' + postData.email;
  // }

  if (window.config.registerSettings.registerType === '6') {
    postData.email = 'fake@nodomain.com';
  }

  // console.log('DEBUG [setPartialAccountSaga] PAYLOAD', { action: action.data, postData });

  try {
    const response = yield axios({
      url: '/api/register/setPartial',
      method: 'post',
      data: postData,
    });

    // console.log('DEBUG [setPartialAccountSaga] RESPONSE', response);

    if (!response.status) {
      throw new Error('[ERROR] REGISTER missing status!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] REGISTER Validation not triggered!');
    }

    if (response.result && response.result.http) {
      throw new Error(`[ERROR] ${ResponseErrorMessages.get(1)}`);
    }

    if (response.result.paid) PlayOnline.setPaid(response.result.paid);
    if (response?.result?.existingAccount) {
      // console.log('DEBUG [setPartialAccountSaga] existingAccount', response.result.existingAccount);
      yield put(
        partialAccountResponse({
          success: true,
          existingAccount: response.result.existingAccount,
          existingAccountData: {
            phone: postData.phone,
            phone_country_code: postData.phone_country_code,
          },
        }),
      );
    } else if (response?.result?.ResponseCode || response?.result?.ResponseMessage) {
      yield put(
        partialAccountResponse({
          success: false,
          errorCode: response.result.ResponseCode ?? 1,
          errorMessage: response.result.ResponseMessage ?? '',
        }),
      );
    } else {
      // console.log('DEBUG [setPartialAccountSaga] existingAccount ELSE', response);
      yield put(partialAccountResponse({ success: true }));
    }
  } catch (error) {
    // console.log('DEBUG [setPartialAccountSaga] ERROR', error);
    yield put(partialAccountResponse({ success: false }));
  }
}
